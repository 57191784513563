import React, { useState } from "react";
import styles from "./Tooltip.module.scss";

export const Tooltip = ({
  children,
  title,
  placement = "bottom",
  className = "",
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <div
      className={`${styles.tooltipContainer} ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {children}
      {isVisible && (
        <div className={`${styles.tooltip} ${styles[placement]}`}>{title}</div>
      )}
    </div>
  );
};
