/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  chosenProductsSelector,
  companiesListSelector,
  configuratorReducerSelector,
} from "../reduxStore/slices/selectors";
import { useOutsideClick } from "hooks/useOutsideClick";
import { ConfiguratorLine } from "./components/ConfiguratorLine";
import { AddProductItem } from "./components/AddProductItem";
import { ProductItem } from "./components/ProductItem";
import {
  addCellToProduct,
  addProduct,
  deleteProductById,
  resetConfigurator,
  selectProduct,
  setOptionalFeaturesForLineItem,
  setOtherOptionForLineItem,
  setSelectedCellToView,
  setSelectedLineItem,
  incrementOptionalFeatureCountForLineItem,
  decrementOptionalFeatureCountForLineItem,
} from "../reduxStore/slices/configuratorSlice";
import { SelectCellDialog } from "./components/SelectCellDialog";
import { useDialog } from "hooks/useDialog";
import { useTranslation } from "hooks/useTranslation";
import { CellItem } from "./components/CellItem";
import { Button } from "shared/components/Button";
import { StandardFeatureItem } from "./components/StandardFeatureItem";
import { OptionRowItem } from "./components/OptionRowItem";
import { OptionCheckLine } from "./components/OptionCheckLine";
import { ConfirmDialog } from "shared/components/ConfirmDialog";
import { VerticalCircularSteps } from "shared/components/VerticalCircularSteps";
import { useScrollSpy } from "hooks/useScrollSpy";
import { downloadFile } from "utils/downloadUtils";
import styles from "./Configurator.module.scss";
import CircularProgress from "@mui/material/CircularProgress";

import {
  IconRotate,
  IconDownload,
  IconConfiguratorSec,
  IconInfoBold,
  IconMaterialSymbolsStar,
  IconFluentOptions,
} from "icons";
import { apiClient } from "services/api";
import { constructPdfExportBody } from "utils/configuratorUtils";
import { getError } from "utils/errMessageUtils";
import { useParams } from "react-router-dom";

export const Configurator = ({
  isOpen,
  setIsOpen,
  handleClickOptionShowMore,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useTranslation();
  const params = useParams();

  const [hasOverflow, setHasOverflowList] = useState(false);
  const chosenProducts = useSelector(chosenProductsSelector);
  const { selectedCellToView, selectedLineItem } = useSelector(
    configuratorReducerSelector
  );
  const configuratorData = useSelector(configuratorReducerSelector);
  const companies = useSelector(companiesListSelector);
  const [activeStandardFeature, setActiveStandardFeature] = useState();
  const [currentSection, setCurrentSection] = useState(0);
  const [isExportBtnLoading, setIsExportBtnLoading] = useState(false);

  const contentRef = useRef(null);
  const confirmDuplicateDialogRef = useRef(null);
  const lineItemsRef = useRef(null);
  const popoverRef = useRef(null);
  const cellsDialogRef = useRef(null);
  const resetDialogRef = useRef(null);
  const tooltipContentRef = useRef(null);
  const configuratorInnerContentRef = useRef(null);

  const excludeRefs = [
    popoverRef,
    cellsDialogRef,
    resetDialogRef,
    confirmDuplicateDialogRef,
    tooltipContentRef,
  ];

  const dispatch = useDispatch();

  const cellSelectDialog = useDialog(); // isOpen, dialogData, openDialog, closeDialog,
  const confirmResetDialog = useDialog();

  const standardFeatures = selectedLineItem?.product?.standard_features?.filter(
    feat => feat.available
  );

  const isExpanded = isOpen && selectedLineItem;

  const sections = [
    { number: 0, id: "product-items" },
    { icon: <IconConfiguratorSec />, number: 1, id: "product-details" },
    { icon: <IconInfoBold />, number: 2, id: "cells-block" },
    { icon: <IconMaterialSymbolsStar />, number: 3, id: "standard-features" },
    { icon: <IconFluentOptions />, number: 4, id: "options" },
  ];

  useScrollSpy(sections, setCurrentSection, contentRef);

  const handleCloseDialog = () => {
    document.getElementById("configurator-inner-content").scrollTop = 0;
    setIsOpen(false);
    dispatch(setSelectedLineItem(null));
  };

  useOutsideClick(contentRef, handleCloseDialog, "", excludeRefs);
  useOutsideClick(cellsDialogRef, () => cellSelectDialog.closeDialog());

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    document.getElementById("configurator-inner-content").scrollTop = 0;
    if (isOpen) {
      dispatch(setSelectedLineItem(null));
    }
  };

  const handleAddProductLineItem = () => {
    dispatch(addProduct());
    requestAnimationFrame(() => {
      lineItemsRef.current.scrollTo({
        left: lineItemsRef.current.scrollWidth + 180,
        behavior: "smooth",
      });
    });
  };

  const handleClickDelete = id => {
    const index = chosenProducts.findIndex(
      product => product.lineItemId === id
    );
    const isSelectedProduct =
      selectedLineItem && selectedLineItem.lineItemId === id;

    dispatch(deleteProductById({ id }));

    if (isSelectedProduct) {
      let foundNonEmptyProduct = false;
      let newSelectedProduct = null;

      // Check previous products
      for (let i = index - 1; i >= 0; i--) {
        if (chosenProducts[i] && chosenProducts[i].product) {
          newSelectedProduct = chosenProducts[i];
          foundNonEmptyProduct = true;
          break;
        }
      }

      // If no previous non-empty product is found, check next products
      if (!foundNonEmptyProduct) {
        for (let i = index + 1; i < chosenProducts.length; i++) {
          if (chosenProducts[i] && chosenProducts[i].product) {
            newSelectedProduct = chosenProducts[i];
            break;
          }
        }
      }

      dispatch(setSelectedLineItem(newSelectedProduct));
    }
  };

  const handleAddProduct = (lineItemId, product, cellsCount, cells) => {
    dispatch(selectProduct({ lineItemId, product, cellsCount, cells }));
  };

  const handleSelectCell = properties => {
    const { data, product, selectedCell } = properties;
    const { lineItemId, cells, idx } = data;

    dispatch(
      addCellToProduct({
        lineItemId,
        selectedCell,
        product,
        cells,
        idx,
        updateSelectedItem: isExpanded,
      })
    );
    cellSelectDialog.closeDialog();
  };

  const handleOpenSelectCell = (lineItemId, product, cells, idx) => {
    const clickedCell = cells[idx];

    if (!clickedCell || !clickedCell.name) {
      cellSelectDialog.openDialog({ lineItemId, product, cells, idx });
    } else {
      return;
    }
  };

  const handleSelectLineItem = item => {
    if (item.product && item.lineItemId !== selectedLineItem?.lineItemId) {
      const selectingCell =
        item.cells[0] && !item.cells[0].isEmpty ? item.cells[0] : null;
      dispatch(setSelectedLineItem(item));
      dispatch(setSelectedCellToView(selectingCell));
      setActiveStandardFeature(item?.product?.standard_features?.[0] || null);
    }
  };

  const handleOpenResetDialog = () => {
    confirmResetDialog.openDialog();
  };

  const handleCloseResetDialog = () => {
    confirmResetDialog.closeDialog();
  };

  const handleSelectCellToView = cell => {
    dispatch(setSelectedCellToView(cell));
    setActiveStandardFeature(
      selectedLineItem?.product?.standard_features?.[0] || null
    );
  };

  const handleSelectStandardFeature = feature => {
    setActiveStandardFeature(feature);
  };

  const handleToggleOptionalFeature = (
    newCheckedValue,
    selectedLineItem,
    optionalFeature
  ) => {
    let resetValue = false;
    let setCountToOne = false;
    if (!newCheckedValue) resetValue = true;
    if (newCheckedValue) setCountToOne = true;
    dispatch(
      setOptionalFeaturesForLineItem({
        newCheckedValue,
        selectedLineItem,
        optionalFeature,
        resetValue,
        setCountToOne,
      })
    );
  };

  const handleIncrementOptionCount = (
    count,
    selectedLineItem,
    optionalFeature
  ) => {
    dispatch(
      incrementOptionalFeatureCountForLineItem({
        count,
        selectedLineItem,
        optionalFeature,
      })
    );
  };

  const handleDecrementOptionCount = (
    count,
    selectedLineItem,
    optionalFeature
  ) => {
    dispatch(
      decrementOptionalFeatureCountForLineItem({
        count,
        selectedLineItem,
        optionalFeature,
      })
    );
  };

  const handleToggleOtherOption = (
    newCheckedValue,
    selectedLineItem,
    otherOption
  ) => {
    dispatch(
      setOtherOptionForLineItem({
        newCheckedValue,
        selectedLineItem,
        otherOption,
      })
    );
  };

  const handleClickSection = sectionNumber => {
    setCurrentSection(sectionNumber);
  };

  const handleResetLocalState = () => {
    dispatch(setSelectedCellToView(null));
    dispatch(setSelectedLineItem(null));
    setActiveStandardFeature(null);
  };

  const handleReset = () => {
    confirmResetDialog.closeDialog();
    dispatch(resetConfigurator());
  };

  const handleExportPdf = async () => {
    setIsExportBtnLoading(true);
    try {
      const currentCompany = companies.find(
        c =>
          c.name?.toLowerCase() === params.company?.toLowerCase() ||
          c.path?.toLowerCase() === params.company?.toLowerCase()
      );
      const { _persist, ...configuratorDataWithoutPersist } = configuratorData;

      const body = constructPdfExportBody(configuratorDataWithoutPersist);

      const response = await apiClient.post("/device/export", body, {
        params: currentCompany ? { company_id: currentCompany.id } : {},
      });

      downloadFile(response.data.url, "ConfiguredDevicesReport.pdf");
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: "error" });
    } finally {
      setIsExportBtnLoading(false);
    }
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (lineItemsRef.current) {
        const hasOverflow =
          lineItemsRef.current.scrollWidth > lineItemsRef.current.clientWidth;
        setHasOverflowList(hasOverflow);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [chosenProducts]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
      handleResetLocalState();
    };
  }, [isOpen, dispatch]);

  return (
    <>
      <ConfirmDialog
        title={translate("Reset?")}
        description={translate("Are you sure you want to reset?")}
        declineBtnText={translate("Cancel")}
        confirmBtnText={translate("Reset")}
        size="s"
        ref={resetDialogRef}
        withCloseIcon
        handleClose={handleCloseResetDialog}
        handleConfirm={handleReset}
        open={confirmResetDialog.isOpen}
      />
      <div
        className={`${styles.container} ${isOpen && styles.isOpen} ${
          isOpen && selectedLineItem && styles.isExpanded
        }`}
      >
        <SelectCellDialog
          handleSelectCell={handleSelectCell}
          dialogRef={cellsDialogRef}
          open={cellSelectDialog.isOpen}
          onClose={cellSelectDialog.closeDialog}
          dialogData={cellSelectDialog.dialogData}
        />
        <div
          id="configurator-content"
          className={`${styles.configuratorContent} ${
            !!isOpen && selectedLineItem && styles.isExpanded
          }`}
          ref={contentRef}
        >
          {!!selectedLineItem && isOpen && (
            <div className={styles.leftSideSteps}>
              <VerticalCircularSteps
                handleClickStep={handleClickSection}
                steps={sections.filter(f => !!f.number)}
                currentStep={currentSection}
              />
            </div>
          )}
          <ConfiguratorLine onClickItem={handleToggleOpen} />
          <div
            ref={configuratorInnerContentRef}
            id={"configurator-inner-content"}
            className={styles.innerContent}
          >
            <div
              data-section={0}
              id="product-items"
              ref={lineItemsRef}
              className={`${styles.lineItems} ${
                hasOverflow ? styles.hasOverflow : ""
              } custom_scrollbar_block`}
            >
              {!!chosenProducts &&
                chosenProducts?.map((product, idx) => {
                  return (
                    <div key={idx} className={styles.content}>
                      <ProductItem
                        handleAddProduct={selectedProduct => {
                          handleAddProduct(
                            product.lineItemId,
                            selectedProduct,
                            selectedProduct?.cells?.length,
                            selectedProduct?.cells
                          );
                        }}
                        handleSelectLineItem={handleSelectLineItem}
                        handleOpenSelectCell={handleOpenSelectCell}
                        popoverRef={popoverRef}
                        handleClickDelete={e => {
                          e.stopPropagation();
                          handleClickDelete(product.lineItemId);
                        }}
                        productItem={product}
                        selectedLineItem={selectedLineItem}
                        index={idx + 1}
                      />
                    </div>
                  );
                })}
              <div
                onClick={handleAddProductLineItem}
                className={styles.content}
              >
                <AddProductItem index={chosenProducts?.length + 1} />
              </div>
            </div>
            {!!selectedLineItem && isOpen && (
              <>
                <div
                  id="product-details"
                  data-section={1}
                  className={styles.detailsBlock}
                >
                  <div className={styles.labelBlock}>
                    <h2 className={styles.sectionTitle}>
                      {selectedLineItem?.product?.configurator?.title}
                    </h2>
                    <p className={styles.shortDesc}>
                      {selectedLineItem?.product?.configurator
                        ?.additional_title || "1060mm SLAVE PREHEAT"}
                    </p>
                  </div>
                  <div className={styles.contentSection}>
                    <div className={styles.imgSec}>
                      <img
                        src={
                          selectedLineItem?.product?.configurator?.detail_image
                        }
                        alt={selectedLineItem?.product?.name}
                      />
                    </div>
                    {!!selectedLineItem?.product?.parameters && (
                      <div className={styles.params}>
                        {selectedLineItem?.product?.parameters?.map(
                          (opt, idx) => {
                            return (
                              <div className={styles.singleParam} key={idx}>
                                <p className={styles.optName}>{opt.name}</p>
                                <p className={styles.optValue}>{opt.value}</p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                  <p className={styles.description}>
                    {selectedLineItem?.product?.configurator?.description ||
                      selectedLineItem?.product?.details}
                  </p>
                </div>
                <div
                  id="cells-block"
                  data-section={2}
                  className={styles.cellsBlock}
                >
                  <h2 className={styles.sectionTitle}>{translate("Cells")}</h2>
                  <div className={styles.content}>
                    <div className={styles.cellsPart}>
                      {!!selectedLineItem?.cells &&
                        selectedLineItem?.cells?.map((cell, idx) => {
                          const isCellSelected =
                            idx === selectedCellToView?.index;
                          return (
                            <div
                              onClick={() => {
                                if (cell) {
                                  handleSelectCellToView({
                                    ...cell,
                                    index: idx,
                                  });
                                } else {
                                  handleOpenSelectCell(
                                    selectedLineItem.lineItemId,
                                    selectedLineItem.product,
                                    selectedLineItem.cells,
                                    idx
                                  );
                                }
                              }}
                              key={idx}
                              className={`${styles.cellContainer} ${
                                !!isCellSelected && styles.active
                              }`}
                            >
                              <CellItem size={"l"} cell={cell} />
                            </div>
                          );
                        })}
                    </div>
                    {selectedCellToView && (
                      <div className={styles.descPart}>
                        <p className={styles.title}>
                          {selectedCellToView?.configuratorTitle}
                        </p>
                        <span className={styles.desc}>
                          {translate(selectedCellToView?.description)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  id="standard-features"
                  data-section={3}
                  className={styles.standardFeaturesSec}
                >
                  <h2 className={styles.sectionTitle}>
                    {translate("Standard Features")}
                  </h2>
                  <div
                    className={`${styles.featureCircles} ${
                      !selectedLineItem && styles.empty
                    }`}
                  >
                    {standardFeatures?.map((feat, idx) => (
                      <StandardFeatureItem
                        isActive={
                          feat?.id && feat?.id === activeStandardFeature?.id
                        }
                        handleSelect={handleSelectStandardFeature}
                        feature={feat}
                        key={idx}
                      />
                    ))}
                  </div>
                  {selectedLineItem && (
                    <div className={styles.descriptionContent}>
                      <p className={styles.contentTitle}>
                        {activeStandardFeature?.name || ""}
                      </p>
                      <p className={styles.contentDesc}>
                        {activeStandardFeature?.description || ""}
                      </p>
                    </div>
                  )}
                </div>
                <div
                  id="options"
                  data-section={4}
                  className={styles.optionsSec}
                >
                  <div className={styles.spacedBetweenRow}>
                    <h2 className={styles.sectionTitle}>
                      {translate("Options")}
                    </h2>
                  </div>
                  <div className={styles.options}>
                    {selectedLineItem?.product?.optional_features?.map(
                      (opt, idx) => {
                        return (
                          <OptionRowItem
                            isEditable={opt.available && opt.editable}
                            count={opt.count}
                            handleIncrementOptionCount={() =>
                              handleIncrementOptionCount(
                                opt.count,
                                selectedLineItem,
                                opt
                              )
                            }
                            handleDecrementOptionCount={() =>
                              handleDecrementOptionCount(
                                opt.count,
                                selectedLineItem,
                                opt
                              )
                            }
                            tooltipContentRef={tooltipContentRef}
                            name={opt.label_in_configurator}
                            handleClickOptionShowMore={
                              handleClickOptionShowMore
                            }
                            description={opt.description}
                            forwardTo={opt.link}
                            icon={opt.icon}
                            key={idx}
                            disabled={!opt.available || !opt.editable}
                            empty={opt.isEmpty}
                            checked={opt.available && opt.state}
                            onToggle={newCheckedValue => {
                              handleToggleOptionalFeature(
                                newCheckedValue,
                                selectedLineItem,
                                opt
                              );
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
                <div id="other-options" className={styles.otherOptionsSec}>
                  <h2 className={styles.sectionTitle}>
                    {translate("Other Options")}
                  </h2>
                  <div className={styles.otherOptions}>
                    {selectedLineItem?.product?.other_options?.map(
                      (opt, idx) => {
                        return (
                          <OptionCheckLine
                            {...opt}
                            disabled={!opt.available || !opt.editable}
                            key={opt.id || idx}
                            index={idx}
                            label={opt.name}
                            checked={opt.available && opt.state}
                            onToggle={newCheckedValue =>
                              handleToggleOtherOption(
                                newCheckedValue,
                                selectedLineItem,
                                opt
                              )
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </div>
                <div className={styles.footer}>
                  <Button
                    onClick={handleOpenResetDialog}
                    icon={<IconRotate />}
                    bold
                    size="l"
                    type="outlined"
                  >
                    {translate("Reset")}
                  </Button>
                  <Button
                    icon={
                      isExportBtnLoading ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        <IconDownload />
                      )
                    }
                    bold
                    isLoading={isExportBtnLoading}
                    danger={!isExportBtnLoading}
                    size="l"
                    type="outlined"
                    onClick={handleExportPdf}
                    className
                  >
                    {isExportBtnLoading
                      ? `${translate("Exporting")}...`
                      : translate("Export")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
