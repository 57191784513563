import { useEffect, useRef, useState, useMemo } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import {
  EXISTING_KEYS,
  EXISTING_TECHNOLOGY_KEYS,
  FEATURES_INFOS,
  INTRO_TEXT,
  TECHNOLOGY_SLIDES,
} from "./constants";
import { IconChevronDown } from "icons";
import { InformativeBlock } from "shared/components/InformativeBlock";
import { HorizontalProductSwipe } from "shared/components/HorizontalProductSwipe";
import styles from "./Technology.module.scss";

export const Technology = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const { search, state } = location;
  const queryParams = new URLSearchParams(search);
  const name = queryParams.get("name");
  const chosenItemOptName = queryParams.get("option");

  const [defaultActiveSwiperIdx, setDefaultActiveSwiperIdx] = useState(null);
  const horizontalSwiperRef = useRef(null);

  const contentKey = EXISTING_KEYS[name] || "default";
  const technologyContentKey = EXISTING_TECHNOLOGY_KEYS[name] || "default";

  useEffect(() => {
    if (state?.scrollToSwiper && horizontalSwiperRef.current) {
      horizontalSwiperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [state]);

  useEffect(() => {
    if (name && chosenItemOptName) {
      horizontalSwiperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      const selectingSwiperItemIdx = TECHNOLOGY_SLIDES[
        technologyContentKey
      ].findIndex(item => item?.option === chosenItemOptName?.toLowerCase());
      setDefaultActiveSwiperIdx(selectingSwiperItemIdx);
      navigate(location.pathname + location.search, {
        replace: true,
      });
    }
  }, [
    name,
    chosenItemOptName,
    technologyContentKey,
    navigate,
    location.pathname,
    location.search,
  ]);

  const renderBackButton = useMemo(() => {
    if (
      (navigationType === "POP" || navigationType === "PUSH") &&
      location.state?.from === "FeatureItem"
    ) {
      return (
        <p className={styles.backBtn} onClick={() => navigate(-1)}>
          <IconChevronDown color="#404040" />
          Back
        </p>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <div className={styles.container}>
      <p className={styles.welcomeText}>{INTRO_TEXT[name]}</p>
      <div className={styles.infos}>
        {FEATURES_INFOS[name]?.map((info, idx) => (
          <InformativeBlock key={idx} text={info} />
        ))}
      </div>
      <div ref={horizontalSwiperRef} className={styles.productSlideSec}>
        {renderBackButton}
        <HorizontalProductSwipe
          name={name}
          hasBulletsIcons
          defaultActiveSwiperIdx={defaultActiveSwiperIdx}
          slides={TECHNOLOGY_SLIDES[technologyContentKey]}
        />
      </div>
    </div>
  );
};
