import { isOfflineMode } from "utils/checkOfflineMode";

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
const VIDEO_BASE_URL = process.env.REACT_APP_VIDEO_BASE_URL;

const IS_OFFLINE_MODE = isOfflineMode();

// Helper function to get the correct path based on offline mode
const getPath = (onlinePath, offlinePath) => {
  return IS_OFFLINE_MODE ? offlinePath : onlinePath;
};

// Define constants for each image and video path
export const Fusion4Product = getPath(
  `${IMAGE_BASE_URL}products/fusion-4.png`,
  "assets/images/products/fusion-4.png"
);

export const JadeS200MK2Product = getPath(
  `${IMAGE_BASE_URL}products/jade-s-200-mk2.png`,
  "assets/images/products/jade-s-200-mk2.png"
);

export const Pilot = getPath(
  `${IMAGE_BASE_URL}products/pilot.png`,
  "assets/images/products/pilot.png"
);

export const JadeMK2 = getPath(
  `${IMAGE_BASE_URL}products/jade-mk2.png`,
  "assets/images/products/jade-mk2.png"
);

export const QuadronPLC = getPath(
  `${IMAGE_BASE_URL}products/quadron-plc.png`,
  "assets/images/products/quadron-plc.png"
);

export const JadeMK4 = getPath(
  `${IMAGE_BASE_URL}products/jade-mk4.png`,
  "assets/images/products/jade-mk4.png"
);

export const JadePro = getPath(
  `${IMAGE_BASE_URL}products/jade-pro.png`,
  "assets/images/products/jade-pro.png"
);

export const JadeProDex = getPath(
  `${IMAGE_BASE_URL}products/jade-prodex.png`,
  "assets/images/products/jade-prodex.png"
);

// Technology
export const SynchrodexPreHeatLamps = getPath(
  `${IMAGE_BASE_URL}technology/Synchrodex Pro Pre-Heat Lamps.jpg`,
  "assets/images/technology/Synchrodex Pro Pre-Heat Lamps.jpg"
);

export const RingHeater = getPath(
  `${IMAGE_BASE_URL}technology/Ring Heater.jpg`,
  "assets/images/technology/Ring Heater.jpg"
);

// Videos
export const FluxMonitor1 = getPath(
  `${VIDEO_BASE_URL}technology/Drop-Jet Fluxing.mp4`,
  "assets/videos/technology/Drop-Jet Fluxing.mp4"
);

export const MicroNozzleVideo = getPath(
  `${VIDEO_BASE_URL}technology/Micro Nozzle.mp4`,
  "assets/videos/technology/Micro Nozzle.mp4"
);

export const ThermalNozzleVideo = getPath(
  `${VIDEO_BASE_URL}technology/Thermal Nozzle Calibration.mp4`,
  "assets/videos/technology/Thermal Nozzle Calibration.mp4"
);

export const NozzleVerificationVideo = getPath(
  `${VIDEO_BASE_URL}technology/Nozzle Verification.mp4`,
  "assets/videos/technology/Nozzle Verification.mp4"
);

export const TwinBathVideo = getPath(
  `${VIDEO_BASE_URL}technology/Top and Bottom Side Pre-Heat.mp4`,
  "assets/videos/technology/Top and Bottom Side Pre-Heat.mp4"
);

export const CLBottomSidePyroControlVideo = getPath(
  `${VIDEO_BASE_URL}technology/Closed-Loop Bottom Side Pyro Control.mp4`,
  "assets/videos/technology/Closed-Loop Bottom Side Pyro Control.mp4"
);

// Products in options

export const EXISTING_KEYS = {
  Synchrodex: "Synchrodex",
  "Orissa Synchrodex": "Orissa Synchrodex",
  "Orissa Synchrodex Pro": "Orissa Synchrodex Pro",
  "Fusion Layouts": "Fusion Layouts",
};

export const EXISTING_TECHNOLOGY_KEYS = {
  fluxing: "fluxing",
  preheating: "preheating",
  soldering: "soldering",
  other: "other",
};

export const FEATURES = {
  default: [
    {
      xPos: 262,
      yPos: 85,
      text: "IPC-2591 (CFX), Smart Factory ready",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Colour programming camera",
    },
    {
      xPos: 261,
      yPos: 330,
      text: "Drop Jet fluxer",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Flux level sensor",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Multiple level password protection",
    },
    {
      xPos: "30%",
      yPos: 627,
      text: "508mm x 508mm (20' x 20') and 610mm x 610mm (24' x 24') board handling sizes",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "SMEMA compatible",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Internal fume extraction",
    },
    {
      xPos: 1075,
      yPos: 165,
      text: "Day-to-day service kit",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "DC drives on all axes",
    },
  ],
  "Orissa Synchrodex": [
    {
      xPos: 162,
      yPos: 85,
      text: "In-line motor driven auto width adjust through feed synchronous movement conveyor",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Conveyor side clamping",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Integral PC and machine mounted monitor",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Motorised auto-solder top-up (wire feed) & solder level detect",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Heated inerted Nitrogen system",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Titanium Drop-Jet fluxer",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Set of AP solder nozzle tips",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Internal fume extraction",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Colour programming camera",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "Solder wave height measurement and correction",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Pump rpm",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Auto-nozzle conditioning system",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Thermal nozzle calibration system using integrated setting camera",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Windows® based PillarCOMM ‘Point & Click’ interface",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Light stack",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Auto fiducial recognition and correction system",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "Flux level sensor",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "Multiple level password protection",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "PillarPAD offline programming system",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "SMEMA compatible",
    },
    {
      xPos: 775,
      yPos: 135,
      text: "Process viewing camera with record feature",
    },
    {
      xPos: 1164,
      yPos: 80,
      text: "Lead-free capability",
    },
    {
      xPos: 964,
      yPos: 15,
      text: "Day-to-day service kit",
    },
  ],
  "Orissa Synchrodex Pro": [
    {
      xPos: 162,
      yPos: 85,
      text: "508mm x 508mm (20' x 20') and 610mm x 610mm (24' x 24') board handling sizes",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Interchangeable Mechanical or Magnetic solder bath and pump",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Lead-free solder capability",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Inerted Nitrogen system",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Set of AP style solder nozzle tips",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Automatic nozzle conditioning system",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Thermal nozzle calibration",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Solder level detect and motorised wire feeder",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Wave height correction",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "DC drives on all axes",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Colour programming camera",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Automatic fiducial correction",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Drop Jet fluxer",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Internal fume extraction",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Light stack",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Integral PC and monitor",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Multiple level password protection",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "IPC-2591 (CFX), Smart Factory ready",
    },
  ],
  "Fusion Layouts": [
    {
      xPos: 162,
      yPos: 85,
      text: "Integral PC and machine mounted Titanium monitor",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "In-line motor driven width adjust through feed conveyor",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Conveyor side clamping",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "DC servo drives with encoders on X, Y & Z axis",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Inerted Nitrogen system",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Internal fume extraction",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Titanium Drop-Jet fluxer",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Motorised wire feed auto solder top-up & solder level detect",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Solder wave height measurement and correction system",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "Solder bath coding – identifies correct bath for program",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Thermal nozzle calibration system using integrated setting camera",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Auto-nozzle conditioning system",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Pump rpm",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Set of AP solder nozzle tips",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Colour programming camera",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Process viewing camera",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "Automatic fiducial correction system",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "Multiple level password protection",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Light stack",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "Flux level sensor",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "PillarCOMM Windows® based ‘Point & Click’ interface",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Lead-free compatible",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "Day-to-day service kit",
    },
  ],
};

export const FEATURES_INFOS = {
  fluxing: [
    "Drop Jet Flux Heads – Available with multiple interchangeable orifice sizes, enabling a wide range of deposition volumes while accommodating different viscosities and chemistries.",
    "Ultrasonic Flux Heads – Offered in standard and wide-horn versions, allowing for broader dispersion patterns while maintaining control over overspray and volume.",
    "Multi-Head Configurations – Supports dual-chemistry applications with completely separated bottle systems, enhancing flexibility and precision.",
    "Dedicated Purge Locations – Collects overspray efficiently, with automated purging for a clean and consistent process.",
    "Fully Automated Purge &amp; Replenish Systems – Prevents flux from passing through valves, allowing the use of RMA fluxes and other high-solids or high-acid number chemistries without clogging or contamination.",
  ],
  soldering: [
    "JetWave Technology – High-flow JetWave nozzles are available in sizes from 6mm to 60mm. Originally developed for MIL/AERO edge connectors, they have proven ideal for high-mass, high-pin-count connectors due to superior thermal transfer characteristics and accommodation for longer lead protrusions.",
    "AP-1 Wetted Nozzle Tip – A standout performer among wetted nozzles, offering a high range of versatility, excellent debridging performance, and lower cost of ownership compared to competitive options.",
    "Jet_Tip Nozzle – Bridges the gap between AP-1 and JetWave, providing higher flow than AP-1 with full control and enhanced heat transfer. While it sacrifices some debridging performance, it offers flexibility by allowing two different contact sizes in the same nozzle.",
    "Micro Thermal Nozzle – The result of over 20 years of development, the Micro Thermal 1.5mm Nozzle revolutionizes micro soldering. Unlike traditional micro nozzles (under 2.5mm-3mm) that suffer from minimal heat transfer and rapid dewetting, our patented MultiFlow design and advanced surface features allow the Micro Thermal 1.5mm Nozzle to perform like a 4mm nozzle while reaching into spaces with as little as 0.0015” pad spacing (dependent on component height).",
  ],
  preheating: [
    "Topside Closed-Loop IR Heating – Standard on all machines, ensuring the PCB reaches and maintains optimal temperature throughout the cycle. Zones can be activated or deactivated based on product requirements, with intensity adjustable per zone for maximum control.",
    "Bottomside IR Preheat – Complements topside IR heating by accelerating the process from below, ensuring homogeneous heating of internal PCB layers.",
    "Full Convection Oven – Unlike standard hot air blowers, our system leverages edge-loss characteristics to refresh energy around the entire unit, whether a single PCB or a fully housed module. It is unaffected by reflective materials such as plastic and aluminum, working seamlessly with IR systems for rapid, uniform heating.",
    "Stacker Convection Oven – Delivers the benefits of convection heating without extended preheat times. Designed for high-thermal-demand products requiring 6-8 minutes to target temperature, this system achieves an output cycle time of just 20-30 seconds—unmatched by any competitor in the market.",
  ],
  default: [
    "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
    "The machine utilizes an XY positioning system to position the flux head as required. A Drop-Jet flux head is supplied as standard with an ultrasonic head being ordered as an alternative or in addition to the standard head. Both systems provide precise control of the flux being deposited on the board.",
    "Programming and control of the machine is via a PC using the Windows® based new generation PillarCOMM software. Programs and machine configuration are protected by a customer selectable, multilevel password security system.",
    "The machine is freely programmable to cater to different board layouts. Each flux joint is separately programmable to its own set of required parameters. The programming system employs a component data library enabling quantities of similar joints to use the same fluxing data outline. Visually assisted self-teach alignment allows for fast and accurate board programming.",
  ],
  "Orissa Synchrodex": [
    "Designed as a modular system, the Synchrodex range of in-line equipment offers the ultimate in flexibility with the ability to upgrade to high speed throughput when multiple modules are placed together.",
    "Supplied as standard with our patented design Drop-Jet fluxer, this system offers accurate, controlled flux deposition either prior to or during the optional top side preheat function. Preheat can be controlled via an optional top-side closed loop pyrometer system for optimum temperature profile regulation.",
    "Our low maintenance solder bath and pump mechanism moves in three axes. Solder is applied using our proven technology single point AP nozzle design incorporating patented spiral solder return to bath technology offering increased wave stability with reduced potential for solder balls. The system can also accommodate our latest generation micro nozzle, together with Jet-Wave, Wide-Wave and dedicated multi-tube single dip solder technology.",
    "As with all Pillarhouse systems, the soldering process is enhanced by a hot Nitrogen curtain which provides an inert atmosphere for the soldering process and also assists in the prevention of oxidation. This process provides a local preheat to the joint thus reducing thermal shock to localised components.",
    "The Synchrodex is controlled by a PC, through PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
  ],
  "Orissa Synchrodex Pro": [
    "Designed as a modular system, the Synchrodex Pro range of in-line equipment offers the ultimate in flexibility with the ability to upgrade to high speed throughput when multiple modules are placed together.",
    "Supplied as standard with our patented design Drop-Jet fluxer, this system offers accurate, controlled flux deposition either prior to or during the optional top side preheat function. Preheat can be controlled via an optional top-side closed loop pyrometer system for optimum temperature profile regulation.",
    "Our low maintenance solder bath and pump mechanism moves in three axes. Solder is applied using our proven technology single point AP nozzle design incorporating patented spiral solder return to bath technology offering increased wave stability with reduced potential for solder balls. The system can also accommodate our latest generation micro nozzle, together with Jet-Wave, Wide-Wave and dedicated multi-tube single dip solder technology.",
    "As with all Pillarhouse systems, the soldering process is enhanced by a hot Nitrogen curtain which provides an inert atmosphere for the soldering process and also assists in the prevention of oxidation. This process provides a local preheat to the joint thus reducing thermal shock to localised components.",
    "The Synchrodex Pro is controlled by a PC, through new generation PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
  ],
  "Fusion Layouts": [
    "Incorporating high speed PCB transfer, the Orissa Fusion platform offers the ultimate in flexibility, coupled with reduced line length at a lower cost compared to current market offerings.",
    "The standard four station cell can be configured to handle PCB’s up to 381mm x 460mm - fluxer, preheat, solder, solder. For high speed applications this same unit can be configured as fluxer/preheat and up to three solder modules with as many as five heater options.",
  ],
};

export const SYSTEM_OPTIONS = {
  default: [
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
  ],
  "Orissa Synchrodex": [
    "Ultrasonic fluxing",
    "Dual Drop-Jet / ultrasonic fluxing",
    "Top-side instant IR preheat",
    "Closed loop pyrometer temperature control",
    "Bottom-side hot Nitrogen selective preheat",
    "Laser PCB warp correction",
    "Solder reel identification",
    "Solder bath coding - identifies correct bath for program",
    "Encoders on X, Y and Z axis",
    "Nitrogen generator",
  ],
  "Orissa Synchrodex Pro": [
    "High definition process viewing camera",
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
    "Laser-based PCB warp correction",
    "Solder reel identification",
    "Nitrogen generator",
  ],
  "Fusion Layouts": [
    "Ultrasonic fluxing",
    "Dual Drop-Jet / ultrasonic fluxing",
    "Top-side instant IR preheat",
    "Bottom-side IR preheat",
    "Closed loop pyrometer temperature control",
    "Large solder bath for dedicated single dip applications",
    "Laser PCB warp correction",
    "1.5mm micro nozzle",
    "Solder reel identification",
    "Auto-nozzle conditioning system",
    "Larger PCB handling size",
    "Nitrogen generator",
  ],
};

export const MONITORING_OPTIONS = {
  default: [
    "Flux spray monitoring",
    "Flux flow monitoring",
    "Flux spray & flow monitoring",
  ],
  "Orissa Synchrodex": [
    "Flux presence sensor - thermistor style",
    "Flux spray, flow and spray & flow",
    "O2 ppm",
    "Nitrogen flow",
  ],
  "Orissa Synchrodex Pro": [
    "Flux spray, flow and spray & flow",
    "Pump rpm",
    "O2 ppm",
    "Nitrogen flow",
  ],
  "Fusion Layouts": [
    "Flux presence sensor - thermistor style",
    "Flux spray, flow and spray & flow",
    "O2 ppm",
    "Nitrogen flow",
  ],
};

export const TECHNOLOGY_SLIDES = {
  default: [
    {
      type: "image",
      description:
        "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
      content: Fusion4Product,
    },
    {
      type: "image",
      description:
        "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
      content: JadeS200MK2Product,
    },
    {
      type: "image",
      description:
        "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
      content: Fusion4Product,
    },
    {
      type: "image",
      description:
        "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
      content: JadeS200MK2Product,
    },
  ],
  fluxing: [
    {
      type: "video",
      name: "Drop Jet/Ultrasonic Fluxing",
      option: "drop_jet_fluxing",
      description:
        "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
      content: FluxMonitor1,
    },

    {
      type: "video",
      name: "Optical Flux Presence Sensor",
      option: "optical_fluc_presence_sensor",
      description:
        "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
      content: `${VIDEO_BASE_URL}enhancements/Optical Flux Presence Sensor.mp4`,
      enhancements: true,
    },
    {
      type: "image",
      name: "Flux Flow Monitoring",
      option: "flux_flow_monitoring",
      description:
        "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
      content: `${IMAGE_BASE_URL}enhancements/flux_flow_meter.jpg`,
      enhancements: true,
    },
  ],
  preheating: [
    {
      type: "video",
      name: "Top Side IR Pre-Heat",
      option: "top_side_ir",
      description:
        "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
      content: TwinBathVideo,
    },
    {
      type: "video",
      name: "Closed-Loop Top Side Pyro Control",
      option: "closed_loop_control",
      description:
        "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
      content: CLBottomSidePyroControlVideo,
    },
    {
      type: "image",
      name: "Bottom Side IR Pre-Heat",
      option: "bottom_side_ir",
      description:
        "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
      content: SynchrodexPreHeatLamps,
    },
    {
      type: "video",
      name: "Closed-Loop Bottom Side Pyro Control",
      option: "bottom_side_pyro_control",
      description:
        "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
      content: CLBottomSidePyroControlVideo,
    },
    {
      type: "image",
      name: "Ring Heater",
      option: "ring_heater",
      description:
        "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
      content: RingHeater,
    },
  ],
  soldering: [
    {
      type: "video",
      name: "1.2mm Micro Nozzle",
      option: "1.2mm_micro_nozzle",
      description:
        "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
      content: MicroNozzleVideo,
    },
    {
      type: "video",
      name: "Thermal Calibration",
      option: "thermal_calibration",
      description:
        "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
      content: ThermalNozzleVideo,
    },
    {
      type: "video",
      name: "Nozzle Verification",
      option: "nozzle_verfication",
      description:
        "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
      content: NozzleVerificationVideo,
    },
    {
      type: "video",
      name: "Jetwave",
      option: "jetwave",
      description:
        "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
      content: getPath(
        `${VIDEO_BASE_URL}enhancements/Jet Wave.mp4`,
        "assets/videos/enhancements/Jet Wave.mp4"
      ),
      enhancements: true,
    },
    {
      type: "video",
      name: "Auto Nozzle Tinning",
      option: "auto_nozzle_tinning",
      description:
        "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
      content: `${VIDEO_BASE_URL}enhancements/Auto Nozzle Conditioning.mp4`,
      enhancements: true,
    },
    {
      type: "video",
      name: "Auto Level Detect and Top Up",
      option: "auto_level_detect",
      description:
        "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
      content: `${VIDEO_BASE_URL}enhancements/Feeder short.mp4`,
      enhancements: true,
    },
  ],
  other: [
    {
      type: "image",
      name: "Fiducial Dynamic Correction",
      option: "fiducial_dynamic_correction",
      description:
        "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
      content: `${IMAGE_BASE_URL}enhancements/Fiducial.jpg`,
      enhancements: true,
    },
    {
      type: "video",
      name: "PCB Warpage Correction",
      option: "pcb_warpage_correction",
      description:
        "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact.",
      content: `${VIDEO_BASE_URL}enhancements/boardwarp.mp4`,
      enhancements: true,
    },
    {
      type: "image",
      name: "Electromagnetic Pump",
      option: "electromagnetic_pump",
      description:
        "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation.",
      content: `${IMAGE_BASE_URL}enhancements/mag pump.jpg`,
      enhancements: true,
    },
    {
      type: "video",
      name: "Dual Solder Baths",
      option: "dual_solder_baths",
      description:
        "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
      content: `${VIDEO_BASE_URL}enhancements/TwinBath.mp4`,
      enhancements: true,
    },
    {
      type: "image",
      name: "MES (Manufacturing Equipment Systems)",
      option: "manufacturing_equipment_systems",
      description:
        "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
      content: `${IMAGE_BASE_URL}enhancements/MES.jpg`,
      enhancements: true,
    },
    {
      type: "image",
      name: "Automatic Rework Integration",
      option: "automatic_rework_integration",
      description:
        "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
      content: `${IMAGE_BASE_URL}enhancements/AOI-Automatic.png`,
      enhancements: true,
    },
    {
      type: null,
      name: "Internal Monitoring of Machine Temperature",
      option: "internal_monitoring_temperature",
      description:
        "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
      content: null,
      enhancements: true,
    },
    {
      type: "image",
      name: "Automatic Program ID and Change",
      option: "automatic_program_id",
      description:
        "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
      content: `${IMAGE_BASE_URL}enhancements/Bar code reader.png`,
    },
  ],
};

export const OTHER_CHOICES = [
  {
    name: "Pilot",
    forwardTo: "/product?name=pilot",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: Pilot,
  },
  {
    name: "JADE MKII",
    forwardTo: "/product?name=Jade MKII",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: JadeMK2,
  },
  {
    name: "QUADRON (PLC)",
    forwardTo: "/product?name=Quadron",
    description: "High specification, PLC, rotary soldering machine",
    content: QuadronPLC,
  },
  {
    name: "JADE MKIV",
    forwardTo: "/product?name=Jade MKIV",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: JadeMK4,
  },
  {
    name: "JADE PRO",
    forwardTo: "/product?name=Jade Pro",
    description:
      "Enhanced, entry-level, single point selective soldering system",
    content: JadePro,
  },
  {
    name: "JADE PRODEX",
    forwardTo: "/product?name=JADE Prodex",
    description:
      "Ultra-flexible, offline, multi-platform, quick load twin PCB rotary table selective soldering system",
    content: JadeProDex,
  },
];

export const INTRO_TEXT = {
  fluxing: `Welcome to the Pillarhouse Flux Application Page \n\n
  Pillarhouse provides a comprehensive range of flux application solutions tailored to customer needs, including throughput, flux selection, and application methods. Our advanced sensing systems offer thermal and optical detection, enabling precise estimation or measurement of flux deposition in micrograms. These capabilities integrate seamlessly with our IPC 2591 and MES systems, ensuring high precision and reliability in flux application.`,
  preheating: `Welcome to the Pillarhouse Preheat Technology Page \n\n
Pillarhouse offers the most advanced and comprehensive preheat solutions in the selective soldering market. With decades of experience and a commitment to focused research, our technology provides precision heating options, including top and bottom adjustable zones, IR with multiple wavelengths, closed-loop control, and full convection heating. Our high-speed convection stacker and specialized solutions, such as the bottom ring heater for embedded PCBs in EV modules, set us apart. We excel in meeting the most challenging high-thermal energy requirements, particularly in military, space and automotive applications.`,
  soldering: `Welcome to the Pillarhouse Soldering Technology Page \n\n
Our award-winning and patented soldering technology delivers unparalleled precision and capability. Pillarhouse offers a broad range of nozzle solutions, from standard wetted quick-change nozzles (2.5mm to 16mm) to extended-length versions, as well as high-flow Jet_Tip nozzles (6mm to 45mm). Our industry-leading Micro Thermal 1.5mm Nozzle provides the best keep-out zones while delivering exceptional thermal energy—far superior to standard designs twice its size.\n

Unique to Pillarhouse, our JetWave technology combines high flow rates with tight control, delivering best-in-class thermal performance with nozzles that last for years and require minimal maintenance. Most of our nozzles are available in either screw-in quick-change tips or magnetic tips (Micro nozzles available only in quick-change mechanical tips).`,
  other:
    "Welcome to Pillarhouse, your premier partner in soldering solutions forthe dynamic electric vehicle (EV) industry. With unparalleled expertise and cutting-edge technology, we specialize in providing high-quality soldering services tailored specifically to the unique demands of electric vehicle manufacturing.",
};
