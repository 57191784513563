import { useTranslation } from "hooks/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "reduxStore/slices/configuratorSlice";
import { productsListSelector } from "reduxStore/slices/selectors";
import { addNewProductWithSelect } from "reduxStore/slices/configuratorSlice";
import styles from "./AddToConfiguratorElm.module.scss";
import { IconConfigBolt } from "icons";

export const AddToConfiguratorElm = ({
  setIsOpenConfigurator,
  currentProduct,
}) => {
  const productsList = useSelector(productsListSelector);
  const { translate } = useTranslation();
  const dispatch = useDispatch();

  const handleAddToConfigurator = () => {
    if (currentProduct) {
      const addingProduct = productsList.find(
        prod => prod.device_name === currentProduct.name
      );
      // if (
      //   currentProduct.name !== "Orissa Fusion 4" &&
      //   currentProduct.name.includes("Fusion")
      // ) {
      //   dispatch(addProduct());
      //   setIsOpenConfigurator(true);
      // } else {
      dispatch(
        addNewProductWithSelect({
          product: addingProduct,
          cellsCount: currentProduct?.cells?.length, // currentProduct.cellnum (removed this)
          cells: currentProduct?.cells,
        })
      );
      setIsOpenConfigurator(true);
      // }
    }
  };

  return (
    <div onClick={handleAddToConfigurator} className={styles.container}>
      <IconConfigBolt />
      <span className={styles.text}>{translate("Add to Configurator")}</span>
    </div>
  );
};
