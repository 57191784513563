import { useState } from "react";
import { useSelector } from "react-redux";
import { productsListSelector } from "reduxStore/slices/selectors";
import { useTranslation } from "hooks/useTranslation";
import { MainPopover } from "shared/components/MainPopover";
import { CellItem } from "../CellItem";
import styles from "./ProductItem.module.scss";
import { IconSettings, IconSettingsLight, IconDeleteRecycle } from "icons";

export const ProductItem = ({
  index,
  handleClickDelete,
  popoverRef,
  handleAddProduct,
  handleOpenSelectCell,
  handleSelectLineItem,
  productItem,
  selectedLineItem,
}) => {
  const { translate } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const list = useSelector(productsListSelector);
  const productsList = list.filter(p => p.add_to_configurator);

  const { product, cells, lineItemId } = productItem;

  const handlePopoverOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClickProduct = prod => {
    handleAddProduct(prod);
    handlePopoverClose();
  };

  return (
    <div
      className={`${styles.container} ${!!product && styles.hoveredOn} ${
        !!anchorEl && styles.active
      } ${selectedLineItem?.lineItemId === lineItemId && styles.isSelected} ${
        selectedLineItem && styles.isExpanded
      }`}
      onClick={() => handleSelectLineItem(productItem)}
    >
      {!product && <IconSettingsLight />}
      {!!product && (
        <>
          <div className={styles.cellsBlock}>
            {product.cells?.map((cell, idx) => {
              const actualCell = cells?.[idx] || cell;
              return (
                <div
                  key={idx}
                  className={styles.cellItem}
                  onClick={e => {
                    e.stopPropagation();
                    handleOpenSelectCell(lineItemId, product, cells, idx);
                  }}
                >
                  {actualCell?.image ? (
                    <CellItem cell={actualCell} size="xs" />
                  ) : (
                    <CellItem size="xs" />
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.itemProductImgBlock}>
            <img
              alt={product?.name}
              src={product.configurator?.item_image || product.img}
              className={styles.itemProductImg}
            />
          </div>
        </>
      )}
      <div className={styles.order}>{index}</div>
      <div className={styles.icons}>
        {!product && (
          <>
            <div
              onClick={handlePopoverOpen}
              className={`${styles.iconItem} ${!!anchorEl && styles.active}`}
            >
              <IconSettings color="#2E2E2E" width={20} height={20} />
            </div>
            <MainPopover
              popoverRef={popoverRef}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
            >
              <div className={styles.popoverContent}>
                <h3 className={styles.title}>{translate("Choose Machine")}</h3>
                <div className={styles.products}>
                  {productsList.map((prod, ind) => {
                    return (
                      <p
                        onClick={() => handleClickProduct(prod)}
                        className={`${styles.prodName}`}
                        key={ind}
                      >
                        {prod?.name}
                      </p>
                    );
                  })}
                </div>
              </div>
            </MainPopover>
          </>
        )}
        <div onClick={handleClickDelete} className={styles.iconItem}>
          <IconDeleteRecycle />
        </div>
      </div>
    </div>
  );
};
