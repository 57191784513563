import { useTranslation } from "hooks/useTranslation";
import styles from "./InfoBlock.module.scss";

export const InfoBlock = ({ section, title, paragraphs }) => {
  const { translate } = useTranslation();

  return (
    <div className={styles.container}>
      <h3 className={styles.secTitle}>{translate(section)}</h3>
      <h4 className={styles.title}>{translate(title)}</h4>
      <ul className={styles.content}>
        {paragraphs.map((paragraph, idx) => (
          <li key={idx} className={styles.paragraph}>
            {translate(paragraph)}
          </li>
        ))}
      </ul>
    </div>
  );
};
