import { v4 as uuidv4 } from "uuid";
import { IconUKFlag, IconUSFlag, IconChinaFlag, IconMexicoFlag } from "icons";
import EnglandMapImg from "assets/mapImgs/england-location-map.png";
import USMapImg from "assets/mapImgs/us-location-map.png";
import ChinaMapImg from "assets/mapImgs/china-location-map.png";
import MexicoMapImg from "assets/mapImgs/mexico-location-map.png";

// Location building and space images
import InternationalLocationImg from "assets/locations/international.png";
import USALocationImg from "assets/locations/USA.png";
import ChinaLocationImg from "assets/locations/China.png";
import MexicoLocationImg from "assets/locations/Mexico.png";
import IndiaLocationImg from "assets/locations/India.png";
import JapanLocationImg from "assets/locations/Japan.png";
import CzechLocationImg from "assets/locations/Czech.png";
import BulgariaLocationImg from "assets/locations/Bulgaria.png";
import USA2LocationImg from "assets/locations/USA2.png";
import IsraelLocationImg from "assets/locations/Israel.png";
import CaliforniaLocationImg from "assets/locations/California.png";
import USA3LocationImg from "assets/locations/USA3.png";
import ItalyLocationImg from "assets/locations/Italy.png";
import USAArvadaLocationImg from "assets/locations/USAArvada.png";
import USAHaywardLocationImg from "assets/locations/USAHayward.png";
import PolandLocationImg from "assets/locations/Poland.png";
import VietnamLocationImg from "assets/locations/Vietnam.png";
import USAAustinLocationImg from "assets/locations/USAAustin.png";
import RussiaPeterburgLocationImg from "assets/locations/RussiaPeterburg.png";
import USADownersGroveLocationImg from "assets/locations/USADownersGrove.png";
import HungaryLocationImg from "assets/locations/Hungary.png";
import USAOdessaLocationImg from "assets/locations/USAOdessa.png";
import ItalyCantuLocationImg from "assets/locations/ItalyCantu.png";
import USAWakeForestLocationImg from "assets/locations/USAWakeForest.png";
import TaiwanLocationImg from "assets/locations/Taiwan.png";
import HongKongLocationImg from "assets/locations/HongKong.png";
import USAPortlandLocationImg from "assets/locations/USAPortland.png";
import TaiwanNewTaipeiLocationImg from "assets/locations/TaiwanNewTaipei.png";
import USAWabanLocationImg from "assets/locations/USAWaban.png";
import MexicoJaliscoLocationImg from "assets/locations/MexicoJalisco.png";
import SauthAfricaLocationImg from "assets/locations/SauthAfrica.png";
import KoreaLocationImg from "assets/locations/Korea.png";
import ThailandLocationImg from "assets/locations/Thailand.png";
import AustraliaLocationImg from "assets/locations/Australia.png";
import USALiverpoolLocationImg from "assets/locations/USALiverpool.png";
import GermanyNeubulachLocationImg from "assets/locations/GermanyNeubulach.png";
import TurkeyLocationImg from "assets/locations/Turkey.png";
import USAHuntsvilleLocationImg from "assets/locations/USAHuntsville.png";
import USACaliforniaGeneralLocationImg from "assets/locations/USACaliforniaGeneral.png";
import PortugalLocationImg from "assets/locations/Portugal.png";
import USAScottsdaleLocationImg from "assets/locations/USAScottsdale.png";
import GermanyBendorfLocationImg from "assets/locations/GermanyBendorf.png";
import FinlandLocationImg from "assets/locations/Finland.png";
import BelgiumLocationImg from "assets/locations/Belgium.png";
import CanadaOntarioLocationImg from "assets/locations/CanadaOntario.png";
import AtibaiaLocationImg from "assets/locations/Atibaia.png";
import USAElPasoLocationImg from "assets/locations/USAElPaso.png";
import PolandKrakowLocationImg from "assets/locations/PolandKrakow.png";
import CebuCityLocationImg from "assets/locations/CebuCity.png";
import KoreaSeoulLocationImg from "assets/locations/KoreaSeoul.png";
import USAMuriettaLocationImg from "assets/locations/USAMurietta.png";
import SwedenLocationImg from "assets/locations/Sweden.png";
import { formatDurationToMinsSeconds } from "utils/dateUtils";

export const COUNTRY_LOCATION_DETAILS = {
  uk: {
    flag: <IconUKFlag />,
    mapImg: EnglandMapImg,
    name: "UK",
    phone: "+44 (0)1245 491333",
    email: "sales@pillarhouse.co.uk",
    address:
      "Pillarhouse International Ltd Rodney Way Chelmsford Essex CM1 3BY UK",
    mapHTMLSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.6616746090567!2d0.44731857663286767!3d51.72093197186264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8ea4a1481c2f1%3A0xd26482868950cc5b!2sRodney%20Way%2C%20Chelmsford%20CM1%203BY%2C%20UK!5e0!3m2!1sen!2sam!4v1712572373279!5m2!1sen!2sam",
    mapUrl:
      "https://www.google.com/maps/place/Rodney+Way,+Chelmsford+CM1+3BY,+UK/@51.720932,0.4473186,17z/data=!3m1!4b1!4m6!3m5!1s0x47d8ea4a1481c2f1:0xd26482868950cc5b!8m2!3d51.720932!4d0.4498935!16s%2Fg%2F1tdj3gw9?entry=ttu",
  },
  us: {
    flag: <IconUSFlag />,
    mapImg: USMapImg,
    name: "US",
    phone: "+1 847 593 9080",
    email: "sales@pillarhouse.co.uk",
    address:
      "Pillarhouse USA Inc. 201 Lively Boulevard Elk Grove Village IL 60007",
    mapHTMLSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.9187224185166!2d-87.97112972390796!3d42.023475971226254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405d082c835451fb%3A0x9f11d992660a985f!2sPillarhouse%20USA%20Inc.!5e0!3m2!1sen!2sam!4v1712572425724!5m2!1sen!2sam",
    mapUrl:
      "https://www.google.com/maps/place/Pillarhouse+USA+Inc./@42.023476,-87.9711297,17z/data=!3m1!4b1!4m6!3m5!1s0x405d082c835451fb:0x9f11d992660a985f!8m2!3d42.023476!4d-87.9685548!16s%2Fg%2F11b7g326sz?entry=ttu",
  },
  china: {
    flag: <IconChinaFlag />,
    mapImg: ChinaMapImg,
    name: "China",
    phone: "+86 (0)512 65860460",
    email: "sales@pillarhouse.co.uk",
    address:
      "Pillarhouse Soldering Systems (Suzhou) Co. Ltd. 11 Yuanqi Road Xiangcheng Suzhou China 215131",
    mapHTMLSrc:
      "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d435710.6241852466!2d120.27930857045554!3d31.441962851070226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPillarhouse%20Soldering%20Systems%20Co.%20Ltd.%2011%20Yuanqi%20Road%20Xiangcheng%20Suzhou%20China%20215131!5e0!3m2!1sen!2sam!4v1712572460564!5m2!1sen!2sam",
    mapUrl:
      "https://www.google.com/maps/search/Pillarhouse+Soldering+Systems+(Suzhou)+Co.+Ltd.+11+Yuanqi+Road+Xiangcheng+Suzhou+China+/@31.40625,120.6317651,17z/data=!3m1!4b1?entry=ttu",
  },
  mexico: {
    flag: <IconMexicoFlag />,
    mapImg: MexicoMapImg,
    name: "Mexico",
    phone: "+52 33 3156 5653",
    email: "sales@pillarhouse.co.uk",
    address:
      "Pillarhouse Mexico MMS Av. Santa Catalina 1041 Zapopan Jalisco CP 45050 Mexico",
    mapHTMLSrc:
      "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d238881.2006681067!2d-103.56826280205885!3d20.68914880429691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPillarhouse%20Mexico%20MMS%20Av.%20Santa%20Catalina%201041%20Zapopan%20Jalisco%20CP%2045050%20Mexico!5e0!3m2!1sen!2sam!4v1712572491052!5m2!1sen!2sam",
    mapUrl:
      "https://www.google.com/maps/search/Pillarhouse+Mexico+MMS+Av.+Santa+Catalina+1041+Zapopan+Jalisco+CP+Mexico/@20.657895,-103.4212816,17z/data=!3m1!4b1?entry=ttu",
  },
};

export const LANGUAGES = [
  { name: "UK", code: "en", flag: <IconUKFlag /> },
  { name: "China", code: "zh", flag: <IconChinaFlag /> },
];

export const PRODUCTS_FOR_CONFIGURATOR = [
  {
    name: "Synchrodex",
    nameInConfigurator: "Synchrodex Solder",
    cellsCount: 1,
  },
  {
    name: "Orissa Synchrodex Pro",
    nameInConfigurator: "Synchrodex Single Solder",
    cellsCount: 1,
  },
  {
    name: "Orissa Synchrodex Combo",
    nameInConfigurator: "Synchrodex Twin Solder",
    cellsCount: 2,
  },
  { name: "Fusion Layouts", nameInConfigurator: "Fusion" },
];

export const PRODUCT_REGULAR_NAME_BY_CONFIG_NAME = {
  "Synchrodex Solder": "Orissa Synchrodex",
  "Synchrodex Single Solder": "Orissa Synchrodex Pro",
  "Synchrodex Twin Solder": "Orissa Synchrodex Combo",
  "Fusion 3": "Fusion Layouts",
  "Fusion 4": "Fusion Layouts",
};

export const LOCATIONS = [
  {
    id: 1,
    name: "Pillarhouse International Ltd",
    address: "Rodney Way Chelmsford Essex CM1 3BY UK",
    representative: "John Doe",
    phone: "+1 847 593 9080",
    fax: "+1 847 593 9084",
    website: "www.pillarhouseusa.com",
    email: "sales@pillarhouseusa.com",
    image: InternationalLocationImg,
  },
  {
    id: 2,
    name: "Pillarhouse USA Inc.",
    address: "201 Lively Boulevard Elk Grove Village IL 60007 USA",
    representative: "John Doe",
    phone: "+1 847 593 9080",
    fax: "+1 847 593 9084",
    website: "www.pillarhouseusa.com",
    email: "sales@pillarhouseusa.com",
    image: USALocationImg,
  },
  {
    id: 3,
    name: "Pillarhouse Soldering Systems (Suzhou) Co. Ltd.",
    address: "11 Yuanqi Road Xiangcheng Suzhou China 215131",
    representative: "John Doe",
    phone: "+1 847 593 9080",
    fax: "+1 847 593 9084",
    website: "www.pillarhouseusa.com",
    email: "sales@pillarhouseusa.com",
    image: ChinaLocationImg,
  },
  {
    id: 4,
    name: "Pillarhouse Mexico MMS",
    address: "Av. Santa Catalina 1041 Zapopen Jalisco CP 45050 Mexico",
    representative: "John Doe",
    phone: "+1 847 593 9080",
    fax: "+1 847 593 9084",
    website: "www.pillarhouseusa.com",
    email: "sales@pillarhouseusa.com",
    image: MexicoLocationImg,
  },
  {
    id: 5,
    name: "Accurex Solutions (P) Ltd",
    address:
      "#782. 3rd Main. 1st Cross., BEML Layout, 5th Stage, Rajarajeswarinagar, Bangalore 560098, India",
    representative: "Mr K. N. Rammohan",
    image: IndiaLocationImg,
  },
  {
    id: 6,
    name: "Alpha Electronics Corp.",
    address:
      "3-20-21, Minami-Hatogaya, Kawaguchi-shi, Saitama, 334-0013, Japan",
    representative: "Jun Yoshino",
    image: JapanLocationImg,
  },
  {
    id: 7,
    name: "Amtech spol. s r.o.",
    address: "Bohuslava Martinů, 941/41b, 602 00 Brno, Czech Republic",
    representative: "Radek Nekarda",
    image: CzechLocationImg,
  },
  {
    id: 8,
    name: "Amtest EOOD",
    address: "Blvd. Arsenalski 81 V,, app. 41,, Sofia, 1421, Bulgaria",
    representative: "Pavlin Kozarov",
    image: BulgariaLocationImg,
  },
  {
    id: 9,
    name: "Argo-Zeta",
    address: "PO Box 998 - 2320 Trumbauersville Road, Quakertown, PA 18951 USA",
    representative: "Bob Slusarczyk",
    image: USA2LocationImg,
  },
  {
    id: 10,
    name: "ASI Technologies",
    address: "Netser-Sereni, 70395, Israel",
    representative: "Mr Kobi Ventura",
    image: IsraelLocationImg,
  },
  {
    id: 11,
    name: "Assembly Resource",
    address: "43835 North Moray Street, Fremont, California, 94539-5940",
    representative: "Leigh Jackson",
    image: CaliforniaLocationImg,
  },
  {
    id: 12,
    name: "BarTron Inc.",
    address: "2646 Riverside Dr., Trenton,, MI  48183 USA",
    representative: "Bob Papp",
    image: USA3LocationImg,
  },
  {
    id: 13,
    name: "Cabiotec SRL",
    address: "Via Romolo Bitti 6,, 20125 Milano, Italy",
    representative: "Carla Fiorentino",
    image: ItalyLocationImg,
  },
  {
    id: 14,
    name: "Cluff & Associates",
    address: "8795 Ralston Road, Suite 101, Arvada, CO 80002 USA",
    representative: "Sharon Cluff",
    image: USAArvadaLocationImg,
  },
  {
    id: 15,
    name: "Cornerstone Technical Marketing",
    address: "134 Turlock Way, Hayward, California, 94545 USA",
    representative: "Léo Huerta",
    image: USAHaywardLocationImg,
  },
  {
    id: 16,
    name: "CPS Industrial Engineering Poland Ltd",
    address: "Dluga 47 Mareza, 82-500 Kwidzyn, Poland",
    representative: "Michał Katana",
    image: PolandLocationImg,
  },
  {
    id: 17,
    name: "Creative Engineering Co. Ltd",
    address:
      "9 Nam Hoa Street, Phuoc Long A Ward,, District 9, Ho Chi Minh City, Vietnam",
    representative: "Tran Khoa Tien (Kevin)",
    image: VietnamLocationImg,
  },
  {
    id: 18,
    name: "DG Marketing",
    address: "16238 RR 620 North, Suite F-382, Austin, TX 78717 USA",
    representative: "Kris Evans",
    image: USAAustinLocationImg,
  },
  {
    id: 19,
    name: "Dipaul",
    address: "ul. Professora Popova 23A, St. Petersburg, 197376, Russia",
    representative: "Anton Sizov",
    image: RussiaPeterburgLocationImg,
  },
  {
    id: 20,
    name: "E-Tronix",
    address: "1441 Branding Lane, Suite 110, Downers Grove, IL 60515 USA",
    representative: "Anthony Noto",
    image: USADownersGroveLocationImg,
  },
  {
    id: 21,
    name: "Elas Kft",
    address: "Diofa utca 130, Budapest, H-1162, Hungary",
    representative: "Zoltan Szilassi",
    image: HungaryLocationImg,
  },
  {
    id: 22,
    name: "Electronic Assembly Products",
    address: "16207 Carnoustie Drive, Odessa, FL, 33556 USA",
    representative: "Mark Hendron",
    image: USAOdessaLocationImg,
  },
  {
    id: 23,
    name: "Grassi Trade s.r.l.",
    address: "Via Trieste, 5, 22063 Cantù (CO), Italy",
    representative: "Paolo Grassi",
    image: ItalyCantuLocationImg,
  },
  {
    id: 24,
    name: "I-Tech",
    address: "7101 Cove Lake Drive, Wake Forest, NC 27587 USA",
    representative: "Larry Coole",
    image: USAWakeForestLocationImg,
  },
  {
    id: 25,
    name: "Island Tek International Ltd",
    address:
      "5F., No. 369, Fude 3rd Rd., Xizhi Dist.,, New Taipei City, 22151, Taiwan",
    representative: "Mars Chu",
    image: TaiwanLocationImg,
  },
  {
    id: 26,
    name: "Kasion Automation Ltd.",
    address:
      "Suite 2602-2603, 26/F, New Tech Plaza,, No. 34 Tai Yau Street,San Po Kong,, Kowloon, Hong Kong",
    representative: "Mr Abby Tsoi",
    image: HongKongLocationImg,
  },
  {
    id: 27,
    name: "Kirby & Demarest",
    address: "515 NW Saltzman Rd. Ste. 882, Portland, OR 97229 USA",
    representative: "Steve Kirby",
    image: USAPortlandLocationImg,
  },
  {
    id: 28,
    name: "LoyalSun Tech Co. Ltd",
    address:
      "1F, No. 130, Jian Kang Road,, Jhonghe District, New Taipei City, Taiwan",
    representative: "Mr Hank Tseng",
    image: TaiwanNewTaipeiLocationImg,
  },
  {
    id: 29,
    name: "Matthew Associates Inc.",
    address: "92 Crofton Road, Waban, MA 02468-2115 USA",
    representative: "Clint Buldrini",
    image: USAWabanLocationImg,
  },
  {
    id: 30,
    name: "Mexico Maquila Services",
    address:
      "Av. Santa Catalina 1041. Col. Prados Tepeyac, Zapopan, Jalisco, CP 45050 Mexico",
    representative: "Francisco Rosas",
    image: MexicoJaliscoLocationImg,
  },
  {
    id: 31,
    name: "MyKay Tronics",
    address:
      "27 Newquay Road, New Redruth, Alberton, Johannesburg, South Africa",
    representative: "Dean Rodger",
    image: SauthAfricaLocationImg,
  },
  {
    id: 32,
    name: "Nealon",
    address:
      "NPLab Center 5th Floor, 32-33, LS-ro, 91beon-gil,, Dongan-Gu, Anyang-City, Gyeonggi-Do, Korea, 14119",
    representative: "Huichul Cho",
    image: KoreaLocationImg,
  },
  {
    id: 33,
    name: "Niche Tech (2004) Co. Ltd",
    address:
      "90/120 Moo 6 Soi Thaesaban 2, Buathong Factory 2, Bangkruay-Sainoi Rd., Bangrakyai, Bangbuathong, Nonthaburi 11110, Thailand",
    representative: "Phermpoon Ekpho",
    image: ThailandLocationImg,
  },
  {
    id: 34,
    name: "ONBoard Solutions",
    address: "2 Salisbury Street, Botany, Sydney, NSW 2019, Australia",
    representative: "Joshua Lawson",
    image: AustraliaLocationImg,
  },
  {
    id: 35,
    name: "Performance Technologies",
    address: "Liverpool, NY 13090 USA",
    representative: "Andy Bresnahan",
    image: USALiverpoolLocationImg,
  },
  {
    id: 37,
    name: "Pillarhouse-Maschinen",
    address: "Vertriebs-GmbH & C, Buhlstrasse 12, D-75387 Neubulach 1, Germany",
    representative: "Mr Hans Stockler",
    image: GermanyNeubulachLocationImg,
  },
  {
    id: 38,
    name: "Pro SMT Elektronik A.Ş.",
    address:
      "Yesilbaglar Mah. Selvili Sok. Helis Beyaz Office A Block 2/1, 13 Pendik, Istanbul, Turkey",
    representative: "Hüseyin Yilmaz",
    image: TurkeyLocationImg,
  },
  {
    id: 39,
    name: "Process Automation & Tool, LLC",
    address: "177 Nick Fitcheard Road, Huntsville, AL 35806 USA",
    representative: "Todd Gilmore",
    image: USAHuntsvilleLocationImg,
  },
  {
    id: 40,
    name: "Production Specialties",
    address: "CA",
    representative: "Bill Arnold",
    image: USACaliforniaGeneralLocationImg,
  },
  {
    id: 41,
    name: "ProMasTech Lda",
    address:
      "Parque Empresarial Brejo do Lobo, Rua dos Tractores nº 506, Edificio C,, 2870-607 Alto do Estanque, Portugal",
    representative: "Marco Leite",
    image: PortugalLocationImg,
  },
  {
    id: 42,
    name: "Rich Sales",
    address: "15547 N. 77th Street, Scottsdale, AZ 85260 USA",
    representative: "Dave Heden",
    image: USAScottsdaleLocationImg,
  },
  {
    id: 43,
    name: "Rubröder GmbH Factory Automation",
    address: "Theodor-Neizert-Strasse 1, D-56170 Bendorf, Germany",
    representative: "Mr Wolfgang Riedel",
    image: GermanyBendorfLocationImg,
  },
  {
    id: 44,
    name: "Sincotron Finland Oy",
    address: "Nihtisillankuja 3 B, 02630 Espoo, Finland",
    representative: "Pehr Nordman",
    image: FinlandLocationImg,
  },
  {
    id: 45,
    name: "SMD-Tec",
    address: "9de Liniestraat 23 D, Aarschot, 3200, Belgium",
    representative: "Tom Van Tongelen",
    image: BelgiumLocationImg,
  },
  {
    id: 46,
    name: "SMT Industrial Supply",
    address: "3-200 Memorial Ave, Suite 202, Orillia, Ontario, L3V 5X6, Canada",
    representative: "Geoff Zacour",
    image: CanadaOntarioLocationImg,
  },
  {
    id: 47,
    name: "SMTech Technosolder",
    address:
      "Belém do Pará Street, 298 - Playground Estoril, Atibaia / SP, 12944-120",
    representative: "Sergio R Amico",
    image: AtibaiaLocationImg,
  },
  {
    id: 48,
    name: "SMTechnoReps",
    address: "14005 Sandy Point Ln, El Paso, TX 79938 USA",
    representative: "Eduardo Valles",
    image: USAElPasoLocationImg,
  },
  {
    id: 49,
    name: "Sowa Electronics",
    address: "Agatowa 37A/25, 30-798 Kraków, Poland",
    representative: "Karol Sowa",
    image: PolandKrakowLocationImg,
  },
  {
    id: 50,
    name: "Trans-Tec International Marketing Phils., Inc.",
    address:
      "Unit 506 Ayala Life FGU Ctr., Mindance Ave Cor Biliran Road, Cebu Business Park, Cebu City",
    representative: "Samuel Chen",
    image: CebuCityLocationImg,
  },
  {
    id: 51,
    name: "UnoTech Inc.",
    address:
      "#404 B-Dong SsangYong IT Twin tower, 537, Dunchon-daero, Jungwon-gu, Seongnam-si, Gyeonggi-do,, Seoul 462-723, Korea",
    representative: "Harry Kwon",
    image: KoreaSeoulLocationImg,
  },
  {
    id: 52,
    name: "WittcoSales,Inc.",
    address: "35215 Rockford Way, Murietta, CA 92563 USA",
    representative: "Tom Wittmer",
    image: USAMuriettaLocationImg,
  },
  {
    id: 53,
    name: "Wretom Consilium AB",
    address: "Olof Dalins Vag 16, apt 1401, Stockholm, SE 112 52 Sweden",
    representative: "Lars-Ake Bergstrom",
    image: SwedenLocationImg,
  },
];

export const OPTIONS_BY_LINK_OPTION_KEY = {
  top_side_ir: "Top Side IR Pre-Heat",
  closed_loop_control: "Closed-loop Control",
  bottom_side_ir: "Bottom Side IR Pre-Heat",
  bottom_side_pyro_control: "",
  drop_jet_fluxing: "Drop Jet/Ultrasonic Fluxing",
  fiducial_dynamic_correction: "Fiducial Dynamic Correction",
  pcb_warpage_correction: "PCB Warpage Correction",
  electromagnetic_pump: "Electromagnetic Pump",
  "1.2mm_micro_nozzle": "1.2mm Micro Nozzle",
  jetwave: "Jetwave",
  thermal_calibration: "Thermal Calibration",
  nozzle_verfication: "Nozzle Verification",
  ring_heater: "Ring Heater",
  dual_solder_baths: "Dual Solder Baths",
  optical_fluc_presence_sensor: "Optical Flux Presence Sensor",
  flux_flow_monitoring: "Flux Flow Monitoring",
  manufacturing_equipment_systems: "MES (Manufacturing Equipment Systems)",
  automatic_rework_integration: "Automatic Rework Integration",
  internal_monitoring_temperature: "Internal Monitoring of Machine Temperature",
  auto_level_detect: "Auto Level Detect and Top Up",
  auto_nozzle_tinning: "Auto Nozzle Tinning",
  automatic_program_id: "Automatic Program ID and Change",
};

export const DEFAULT_PRODUCTS_LIST = [
  {
    device_id: 25,
    device_name: "Orissa Fusion 4",
    cells: ["", "", "", ""],
    image: "assets/images/products/product.jpg",
    details:
      "The Orissa Fusion 4 platform delivers unmatched flexibility with high-speed PCB transfer and shorter line lengths at a lower cost than competitors",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image:
      "assets/images/products/orissa-fusion-4/F4 FHSS ISO.png",
    device_pdf_thumbnail: "images/products/orissa-fusion-4/F4_pdf_105.png",
    standard_features: [
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: true,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    other_options: [
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: false,
        state: true,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: true,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    configurator: {
      title: "Fusion 4",
      additional_title:
        "High speed, precise PCB processor designed for maximum output and ease of use",
      detail_image:
        "assets/images/products/orissa-fusion-4/F4 DBATH RENDER FRONT AND TOP VIEW.png",
      item_image:
        "assets/images/products/orissa-fusion-4/F4 RENDER FRONT VIEW.png",
      description:
        'Introducing the Fusion 4: Precision and Versatility Perfected\r\n\r\nElevate your production with the Fusion 4, featuring a generous 15" x 18" board size that effortlessly accommodates larger PCBs. Engineered for seamless integration into your production line, this machine offers unparalleled flexibility with customizable fluxer, and pre-heater configurations designed to optimize your soldering process for any application.\r\n\r\nOur cutting-edge nozzle technologies deliver pinpoint accuracy and efficient solder delivery, reducing waste and enhancing quality. Whether you\'re working with delicate components or managing high-volume production, the Fusion 4 ensures consistent and reliable results with every use.\r\n\r\nUpgrade to the Fusion 4 and experience high-performance soldering that adapts to your needs, elevates your process, and sets a new standard in manufacturing excellence.\r\n',
    },
    parameters: [
      {
        device_id: 25,
        name: "PCB Size",
        value: "381mm x 430mm (15” x 17”)",
        order: 1,
      },
      {
        device_id: 25,
        name: "Edge Clearance",
        value: "Above/below 3mm",
        order: 2,
      },
      {
        device_id: 25,
        name: "Auto Nozzle Verification",
        value: "High-speed camera-based system for nozzle size confirmation",
        order: 5,
      },
      {
        device_id: 25,
        name: "Nozzle Range",
        value: "1.2mm Micro Nozzle to 150mm Wide Wave",
        order: 6,
      },
      {
        device_id: 25,
        name: "Pre-Heat",
        value: "8KW",
        order: 4,
      },
      {
        device_id: 25,
        name: "Height Clearance",
        value: "4-8 individually controlled 1000W lamps",
        order: 3,
      },
    ],
  },
  {
    device_id: 28,
    device_name: "Orissa Fusion Pro 508",
    cells: ["", "", ""],
    image: "assets/images/products/product.jpg",
    details:
      "The Orissa Fusion Pro 508 platform delivers unmatched flexibility with high-speed PCB transfer and shorter line lengths at a lower cost than competitors.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image:
      "assets/images/products/orissa-fusion-pro-508/FPRO ISO.png",
    device_pdf_thumbnail:
      "images/products/orissa-fusion-pro-508/FPRO_pdf_105.png",
    standard_features: [
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: true,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: true,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    other_options: [
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: true,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: true,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: true,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: true,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 1,
        editable: true,
        state: true,
      },
    ],
    configurator: {
      title: "Fusion Pro 508",
      additional_title:
        "High speed, precise selective soldering machine designed for efficiency and reliability",
      detail_image:
        "assets/images/products/orissa-fusion-pro-508/FPRO RENDER FRONT VIEW AND TOP VIEW.png",
      item_image:
        "assets/images/products/orissa-fusion-pro-508/FPRO FRONT RENDER.png",
      description:
        "The ultimate solution for efficient PCB production. Designed for accuracy and speed, the Fusion Pro 508 integrates advanced features with seamless operation.",
    },
    parameters: [
      {
        device_id: 28,
        name: "PCB Size",
        value: "508mm x 508mm (20” x 20”)",
        order: 1,
      },
      {
        device_id: 28,
        name: "Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 28,
        name: "Solder Baths",
        value: "1 or 2 (in Tandem)",
        order: 3,
      },
      {
        device_id: 28,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
      {
        device_id: 28,
        name: "Nozzle Range",
        value: "1.2mm Micro/150mm Wide",
        order: 5,
      },
    ],
  },
  {
    device_id: 40,
    device_name: "Synchrodex Pro 1600 Pre-Heater Module",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "The enhanced, modular in-line selective soldering pre-heater system offers maximum flexibility, with the option to upgrade for high-speed throughput when using multiple modules.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image:
      "assets/images/products/sp1600ph/SP1600 PH RENDER ISO.png",
    device_pdf_thumbnail: "images/products/sp1600ph/SP1600PH_pdf_105.png",
    standard_features: [
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 1,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: false,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: false,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    other_options: [
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: false,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: true,
        state: true,
      },
    ],
    configurator: {
      title: "Synchrodex Pro 1600mm Pre-Heater Module",
      additional_title: "Large Format Pre-Heating Module",
      detail_image:
        "assets/images/products/sp1600ph/SP1600 PH RENDER FRONT VIEW AND TOP VIEW.png",
      item_image:
        "assets/images/products/sp1600ph/SP1600 PH RENDER FRONT VIEW.png",
      description:
        "The SP1600 Preheater delivers precise and efficient PCB thermal processing with 16-32kW of medium-wavelength IR power, featuring fast-acting lamps for optimal heat control. Configurable for top, bottom, or dual heating, the system ensures flexibility while its pyrometer-controlled monitoring provides real-time temperature regulation for consistent results. Fully integrated with the Synchrodex Pro selective soldering line, it simplifies operation by allowing users to set a target temperature and maximum ramp rate, letting the system handle the rest. Designed for high-performance electronics manufacturing, the SP1600 enhances preheat stability, soldering precision, and overall process efficiency.",
    },
    parameters: [
      {
        device_id: 40,
        name: "PCB Size",
        value: "24” x 48”",
        order: 1,
      },
      {
        device_id: 40,
        name: "Conveyor Cap.",
        value: "36 kg",
        order: 2,
      },
      {
        device_id: 40,
        name: "Preheat Control",
        value: "Pyrometer",
        order: 3,
      },
      {
        device_id: 40,
        name: "Pre-Heat Capacity",
        value: "8-16Kw",
        order: 4,
      },
      {
        device_id: 40,
        name: "Footprint",
        value: "3.6m2",
        order: 5,
      },
      {
        device_id: 40,
        name: "Solder Temperature Control",
        value: "<= 2 Degrees C",
        order: 6,
      },
    ],
  },
  {
    device_id: 26,
    device_name: "Orissa Synchrodex Pro 1060mm Pre-Heater Module",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "The enhanced, modular in-line selective soldering system offers maximum flexibility, with the option to upgrade for high-speed throughput when using multiple modules.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/sp1060ph/SP1060_PH_ISO.png",
    device_pdf_thumbnail: "images/products/sp1060ph/SP1060PH_pdf_105.png",
    standard_features: [
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: false,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: false,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: false,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: false,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: false,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    other_options: [
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: false,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: false,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: false,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: false,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    configurator: {
      title: "Synchrodex Pro 1060mm Pre-Heater Module",
      additional_title: "Versatile, high-precision pre-heating machine",
      detail_image:
        "assets/images/products/sp1060ph/SP1060PH RENDER FRONT VIEW AND TOP VIEW.png",
      item_image:
        "assets/images/products/sp1060ph/SP1060 PH RENDER FRONT VIEW.png",
      description:
        "The Synchrodex Pro 1060 Pre-Heater Module is a specialized piece of equipment designed to optimize pre-heating - the precursor to the soldering process. At 1060mm wide, it is engineered to accommodate a maximum board size of up to 610mm x 610mm (24” x 24”). This module comes equipped with top side IR pre-heat as a standard feature, ensuring precise and efficient heating deposition. The combination of its generous size capacity and high-performance",
    },
    parameters: [
      {
        device_id: 26,
        name: "PCB Size",
        value: "24” x 48”",
        order: 1,
      },
      {
        device_id: 26,
        name: "Solder Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 26,
        name: "Flux head orifice",
        value: "100-300 micron",
        order: 5,
      },
      {
        device_id: 26,
        name: "Preheat capacity",
        value: "16 Kw",
        order: 6,
      },
      {
        device_id: 26,
        name: "Footprint",
        value: "3.6m2",
        order: 4,
      },
      {
        device_id: 26,
        name: "Weight Capacity",
        value: "36kg",
        order: 3,
      },
    ],
  },
  {
    device_id: 32,
    device_name: "Synchrodex Pro 1600 Single Bath Solder Module",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "The Synchrodex Pro is a modular, in-line selective soldering system designed for ultimate flexibility and high-speed throughput when multiple modules are combined.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image:
      "assets/images/products/sp1600-sbath/SP1600 SBATH ISO.png",
    device_pdf_thumbnail:
      "images/products/sp1600-sbath/SP1600SBATH_pdf_105.png",
    standard_features: [
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: true,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: true,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    other_options: [
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: true,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: true,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 1,
        editable: true,
        state: true,
      },
    ],
    configurator: {
      title: "Synchrodex Pro 1600mm Single Bath Solder Module",
      additional_title: "Large Format Single XYZ Soldering",
      detail_image:
        "assets/images/products/sp1600-sbath/SP1600 SBATH RENDER FRONT VIEW AND TOP VIEW.png",
      item_image:
        "assets/images/products/sp1600-sbath/SP1600 SBATH RENDER FRONT VIEW.png",
      description:
        "The Synchrodex Pro 1600 Single Bath Solder Module combines large PCB capability, high throughput, and unparalleled flexibility. Featuring dual independent baths, this advanced system allows for full process adaptability and can efficiently process two boards simultaneously with just a click of the mouse. Additionally, modules can be added to further increase throughput while maintaining a single programming location for all operations.",
    },
    parameters: [
      {
        device_id: 32,
        name: "PCB Size",
        value: "1200mm x 610mm (24” x 45”)",
        order: 1,
      },
      {
        device_id: 32,
        name: "Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 32,
        name: "Flux Head Orifice",
        value: "100-300 Micron",
        order: 3,
      },
      {
        device_id: 32,
        name: "Solder Temperature Control",
        value: "<= 2 Degrees C",
        order: 6,
      },
      {
        device_id: 32,
        name: "Footprint",
        value: "3.6m2",
        order: 5,
      },
      {
        device_id: 32,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
    ],
  },
  {
    device_id: 31,
    device_name: "Synchrodex Fluxer Module",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "Compact, modular, system designed for significantly reduced fluxing process times compared to the Solder Module. This in-line system features on-board fluxing and pre-heating.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/sync-f/SYNC F ISO.png",
    device_pdf_thumbnail: "images/products/sync-f/SYNCF_pdf_105.png",
    standard_features: [
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: false,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: false,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    other_options: [
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: false,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: true,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    configurator: {
      title: "Synchrodex Fluxer Module",
      additional_title: "Compact, modular fluxing system",
      detail_image:
        "assets/images/products/sync-f/SYNC F RENDER FRONT VIEW AND TOP VIEW.png",
      item_image: "assets/images/products/sync-f/SYNC F RENDER FRONT VIEW.png",
      description:
        "The Synchrodex Fluxer Module is a compact, high-performance in-line fluxing system designed for speed and precision. It integrates seamlessly with soldering modules and offers configurable Drop-Jet or Ultrasonic flux heads with on-board infrared pre-heating, reducing process times while maintaining quality. Handling PCB sizes up to 457mm x 610mm (18” x 24”), it’s controlled via the user-friendly PillarCOMMX interface and supports offline programming with PillarPAD using Gerber data. With fully customizable parameters for each flux joint, it’s a versatile solution for managing complex board layouts efficiently.",
    },
    parameters: [
      {
        device_id: 31,
        name: "# Flux Heads",
        value: "1-2",
        order: 2,
      },
      {
        device_id: 31,
        name: "Flux Head Orifice",
        value: "100-300 Micron",
        order: 3,
      },
      {
        device_id: 31,
        name: "Module Position",
        value: "Before/After Preheat",
        order: 4,
      },
      {
        device_id: 31,
        name: "Fluxer Type",
        value: "Drop jet or Ultrasonic",
        order: 5,
      },
      {
        device_id: 31,
        name: "Footprint",
        value: "1.4 m2",
        order: 6,
      },
      {
        device_id: 31,
        name: "PCB Size",
        value: "18” x 24”",
        order: 1,
      },
    ],
  },
  {
    device_id: 29,
    device_name: "Orissa Synchrodex Solder Module",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "The Synchrodex is a high-performance, modular in-line selective soldering system designed for ultimate flexibility and scalable high-speed throughput when configured with multiple modules.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/sync-s/SYNC S ISO.png",
    device_pdf_thumbnail: "images/products/sync-s/SYNCS_pdf_105.png",
    standard_features: [
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: true,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: false,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: false,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    other_options: [
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: true,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: true,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: true,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 1,
        editable: true,
        state: true,
      },
    ],
    configurator: {
      title: "Synchrodex Solder Module",
      additional_title:
        "Flexible, in-line, selective soldering with upgradeable high-speed throughput",
      detail_image:
        "assets/images/products/sync-s/SYNC S RENDER FRONT VIEW AND TOP VIEW.png",
      item_image: "assets/images/products/sync-s/SYNC S RENDER FRONT VIEW.png",
      description:
        "The Synchrodex is a high-flexibility, modular in-line selective soldering system, upgradeable to high-speed throughput by linking multiple modules. It features a low-maintenance, three-axis solder bath with a single point AP Nozzle and patented spiral return-to-bath technology for enhanced wave stability and minimal solder balls. It supports advanced Micro Nozzle, Jet Wave, and Wide Wave technologies, handling PCBs up to 457mm x 610mm (18” x 24”).",
    },
    parameters: [
      {
        device_id: 29,
        name: "Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 29,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
      {
        device_id: 29,
        name: "PCB Size",
        value: "18” x 24”",
        order: 1,
      },
      {
        device_id: 29,
        name: "# Flux Head Orifice",
        value: "100-300 Micron",
        order: 3,
      },
      {
        device_id: 29,
        name: "Footprint",
        value: "1.4m2",
        order: 5,
      },
      {
        device_id: 29,
        name: "Solder Temperature Control",
        value: "<= 2 degrees C",
        order: 6,
      },
    ],
  },
  {
    device_id: 35,
    device_name: "Jade MKII",
    cells: [""],
    image: "assets/images/products/MKII ISO.png",
    details:
      "The Jade MKII is an elementary, flexible single-point selective soldering system designed for small to medium batch manufacturers requiring high production flexibility.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/jade-mkii/MKII ISO.png",
    device_pdf_thumbnail: "images/products/jade-mkii/MKII_pdf_105.png",
    standard_features: [
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: false,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: false,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: false,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    other_options: [
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: false,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: false,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: true,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: false,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    configurator: {
      title: "Jade MKII",
      additional_title:
        "Elementary, flexible, single point selective soldering system",
      detail_image:
        "assets/images/products/jade-mkii/MKII RENDER FRONT VIEW AND TOP VIEW.png",
      item_image: "assets/images/products/jade-mkii/MKII RENDER FRONT VIEW.png",
      description:
        "The Jade MKII is an elementary handload selective soldering system designed for small to medium batch manufacturers, delivering high production flexibility at a low cost. It features a universally adjustable tooling carrier for PCBs or pallets up to 457 x 508mm (18” x 20”) and includes a low-maintenance, three-axis solder bath and pump mechanism, ensuring full access to the PCB without compromising quality.",
    },
    parameters: [
      {
        device_id: 35,
        name: "PCB Size",
        value: "24” x 48”",
        order: 1,
      },
      {
        device_id: 35,
        name: "Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 35,
        name: "# Flux Head Orifice",
        value: "100-300 Micron",
        order: 3,
      },
      {
        device_id: 35,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
      {
        device_id: 35,
        name: "Solder Temperature Control",
        value: "<= 2 Degrees C",
        order: 5,
      },
    ],
  },
  {
    device_id: 30,
    device_name: "Orissa Synchrodex Pre-Heat Module",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "Compact, modular, system designed for significantly reduced pre-heat process times compared to the Solder Module. This in-line system features on-board pre-heating.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/sync-ph/SYNC PH ISO.png",
    device_pdf_thumbnail: "images/products/sync-ph/SYNCPH_pdf_105.png",
    standard_features: [
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: false,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: false,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: false,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: false,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 1,
        editable: true,
        state: true,
      },
    ],
    other_options: [
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: false,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: false,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: false,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: false,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: false,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 1,
        editable: true,
        state: true,
      },
    ],
    configurator: {
      title: "Synchrodex Pre-Heater Module",
      additional_title: "Flexible, in-line, modular pre-heating system",
      detail_image:
        "assets/images/products/sync-ph/SYNC PH RENDER FRONT VIEW AND TOP VIEW.png",
      item_image:
        "assets/images/products/sync-ph/SYNC PH RENDER FRONT VIEW.png",
      description:
        "The Synchrodex Pre-Heat Module is built for exceptional PCB pre-heating efficiency, featuring integrated on-board fluxing for enhanced performance. Designed to work seamlessly with the Solder Module, it offers top and bottom IR pre-heating for consistent heat distribution. Capable of handling PCBs up to 457mm x 610mm (18” x 24”), it is managed through the intuitive PillarCOMMX Windows interface for real-time control and monitoring. Its compatibility with the PillarPAD offline programming package allows operators to easily create and refine programs using Gerber data. Fully programmable, it supports precise parameter customization, making it a versatile solution for complex board layouts.",
    },
    parameters: [
      {
        device_id: 30,
        name: "PCB Size",
        value: "18” x 24”",
        order: 1,
      },
      {
        device_id: 30,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
      {
        device_id: 30,
        name: "Footprint",
        value: "1.4m2",
        order: 5,
      },
      {
        device_id: 30,
        name: "Solder Temperature Control",
        value: "<= 2 degrees C",
        order: 6,
      },
      {
        device_id: 30,
        name: "Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 30,
        name: "Flux Heads",
        value: "1-2",
        order: 3,
      },
    ],
  },
  {
    device_id: 34,
    device_name: "Orissa Synchrodex Pro 1060mm Fluxer Module",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "The enhanced, modular in-line selective soldering system offers maximum flexibility, with the option to upgrade for high-speed throughput when using multiple modules.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/sp1060-f/SP1060 F ISO.png",
    device_pdf_thumbnail: "images/products/sp1060-f/SP1060F_pdf_105.png",
    standard_features: [
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: false,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: false,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    other_options: [
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: false,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: false,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    configurator: {
      title: "Synchrodex Pro 1060mm Fluxer Module",
      additional_title: "Versatile, high-precision fluxing machine",
      detail_image:
        "assets/images/products/sp1060-f/SP1060 F RENDER FRONT VIEW AND TOP VIEW.png",
      item_image:
        "assets/images/products/sp1060-f/SP1060 F RENDER FRONT VIEW.png",
      description:
        "The Synchrodex Pro 1060 Fluxer Module is a specialized piece of equipment designed to optimize flux application in PCB manufacturing processes. At 1060mm wide, it is engineered to accommodate a maximum board size of up to 610mm x 610mm (24” x 24”). This module comes equipped with a Drop-Jet flux head as a standard feature, with dual heads optional, ensuring precise and efficient flux deposition. The combination of its generous size capacity and high-performance components makes it an ideal choice for advanced manufacturing environments seeking accuracy and reliability.",
    },
    parameters: [
      {
        device_id: 34,
        name: "PCB Size",
        value: "24” x 48”",
        order: 1,
      },
      {
        device_id: 34,
        name: "# Flux Head Orifice",
        value: "100-300 Micron",
        order: 3,
      },
      {
        device_id: 34,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
      {
        device_id: 34,
        name: "Footprint",
        value: "3.6m2",
        order: 5,
      },
      {
        device_id: 34,
        name: "Solder Temperature Control",
        value: "<= 2 Degrees C",
        order: 6,
      },
      {
        device_id: 34,
        name: "Nozzle Technologies",
        value: "5",
        order: 2,
      },
    ],
  },
  {
    device_id: 36,
    device_name: "Jade Prodex",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "The Jade Prodex is an ultra-flexible, offline, multi-platform twin PCB rotary table selective soldering system tailored for small batch manufacturers seeking high production flexibility.\r\n● It allows for quick solder alloy changes without costly downtime during manual changeovers.\r\n",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/jpx/JPX ISO.png",
    device_pdf_thumbnail: "images/products/jpx/JPX_pdf_105.png",
    standard_features: [
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: true,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    other_options: [
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: false,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: true,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: true,
        state: true,
      },
    ],
    configurator: {
      title: "Jade Prodex",
      additional_title:
        "Flexible, high-precision dual bath selective soldering",
      detail_image:
        "assets/images/products/jpx/JPX RENDER FRONT VIEW AND TOP VIEW.png",
      item_image: "assets/images/products/jpx/JPX RENDER FRONT VIEW.png",
      description:
        "The Jade Prodex is built for small batch manufacturers needing high production flexibility. It enables quick solder alloy changes without costly downtime during manual changeovers and features a handload system with a universal tooling carrier for PCBs or pallets up to 457 x 508mm (18” x 20”). Standard with a twin solder bath setup, it ensures seamless and versatile performance.",
    },
    parameters: [
      {
        device_id: 36,
        name: "# Flux Head Orifice",
        value: "100-300 Micron",
        order: 3,
      },
      {
        device_id: 36,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
      {
        device_id: 36,
        name: "Solder Temperature Control",
        value: "<= 2 Degrees C",
        order: 6,
      },
      {
        device_id: 36,
        name: "PCB Size",
        value: "18” x 20”",
        order: 1,
      },
      {
        device_id: 36,
        name: "Solder Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 36,
        name: "Footprint",
        value: "1.86m2",
        order: 5,
      },
    ],
  },
  {
    device_id: 37,
    device_name: "Jade Pro",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "The Jade Prodex is an ultra-flexible, offline, multi-platform twin PCB rotary table selective soldering system tailored for small batch manufacturers seeking high production flexibility.\r\n● It allows for quick solder alloy changes without costly downtime during manual changeovers.\r\n",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/jp/JP ISO.png",
    device_pdf_thumbnail: "images/products/jp/JP_pdf_105.png",
    standard_features: [
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: false,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: true,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: false,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: false,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    other_options: [
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: true,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: false,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: false,
        state: false,
      },
    ],
    configurator: {
      title: "Jade Pro",
      additional_title: "Ultra-flexible, dual bath selective soldering system",
      detail_image:
        "assets/images/products/jp/JP RENDER FRONT VIEW AND TOP VIEW.png",
      item_image: "assets/images/products/jp/JP RENDER FRONT VIEW.png",
      description:
        "The Jade Pro is built for small batch manufacturers demanding high production flexibility. It enables quick solder alloy changes without costly downtime during manual bath changeovers. This handload system features a universally adjustable tooling carrier for PCBs or pallets up to 457 x 508mm (18” x 20”) and comes standard with a twin solder bath configuration.",
    },
    parameters: [
      {
        device_id: 37,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
      {
        device_id: 37,
        name: "Footprint",
        value: "1.86m2",
        order: 5,
      },
      {
        device_id: 37,
        name: "Solder Temperature Control",
        value: "<= 2 Degrees C",
        order: 6,
      },
      {
        device_id: 37,
        name: "PCB Size",
        value: "457mm x 508mm (18” x 20”)",
        order: 1,
      },
      {
        device_id: 37,
        name: "Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 37,
        name: "Flux Head Orifice",
        value: "100-300 Micron",
        order: 3,
      },
    ],
  },
  {
    device_id: 33,
    device_name: "Orissa Synchrodex Pro 1060mm Solder Module",
    cells: [""],
    image: "assets/images/products/product.jpg",
    details:
      "The enhanced, modular in-line selective soldering system offers maximum flexibility, with the option to upgrade for high-speed throughput when using multiple modules.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image: "assets/images/products/sp1060-s/SP1060 S ISO.png",
    device_pdf_thumbnail: "images/products/sp1060-s/SP1060S_pdf_105.png",
    standard_features: [
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: true,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: true,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    other_options: [
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: false,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: true,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    configurator: {
      title: "Synhrodex Pro 1060mm Solder Module",
      additional_title: "Versatile, high-precision selective soldering machine",
      detail_image:
        "assets/images/products/sp1060-s/SP1060 S RENDER FRONT VIEW AND TOP VIEW.png",
      item_image:
        "assets/images/products/sp1060-s/SP1060 S RENDER FRONT VIEW.png",
      description:
        "Introducing the Pillarhouse Synchrodex Pro Solder Module—a state-of-the-art selective soldering system engineered to excel in high-value PCB manufacturing. With unmatched modular flexibility, this cutting-edge module seamlessly accommodates a diverse spectrum of PCB dimensions, ranging from the standard 420mm x 610mm (16” x 24”) to expansive configurations up to 610mm x 610mm (24” x 24”). Designed for precision and adaptability, the Synchrodex Pro is the ultimate solution for meeting complex production demands with exceptional efficiency and accuracy.\r\n\r\n",
    },
    parameters: [
      {
        device_id: 33,
        name: "Footprint",
        value: "3.6m2",
        order: 5,
      },
      {
        device_id: 33,
        name: "Solder Temperature Control",
        value: "<= 2 Degrees C",
        order: 6,
      },
      {
        device_id: 33,
        name: "PCB Size",
        value: "24” x 48”",
        order: 1,
      },
      {
        device_id: 33,
        name: "# Flux Head Orifice",
        value: "100-300 Micron",
        order: 3,
      },
      {
        device_id: 33,
        name: "# Solder Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 33,
        name: "Pre-Heat Capacity",
        value: "8 Kw",
        order: 4,
      },
    ],
  },
  {
    device_id: 24,
    device_name: "Synchrodex Pro 1600 Dual Bath Solder Module",
    cells: ["", ""],
    image: "assets/images/products/product.jpg",
    details:
      "The Synchrodex Pro 1600 Dual Bath Solder Module combines large PCB capability, high throughput, and unparalleled flexibility. Featuring dual independent baths, this advanced system allows for full process adaptability and can efficiently process two boards simultaneously with just a click of the mouse. Additionally, modules can be added to further increase throughput while maintaining a single programming location for all operations.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    add_to_configurator: true,
    device_options_image:
      "assets/images/products/sp1600dbath/SP1600 DBATH ISO 01.07.24.png",
    device_pdf_thumbnail: "images/products/sp1600dbath/SP1600DBATH_pdf_105.png",
    standard_features: [
      {
        id: "27",
        key: 11,
        name: "Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance your production efficiency with our Machine Mounted Monitor and PC, providing seamless control and monitoring. This integration allows operators to manage the selective soldering process with ease and precision.",
        link: null,
      },
      {
        id: "2",
        key: 1,
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve soldering quality and reduce oxidation with our Inerted Nitrogen System. This advanced feature ensures a clean soldering environment, enhancing the reliability of your connections.",
        link: null,
      },
      {
        id: "3",
        key: 2,
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Maintain optimal solder levels automatically with our Auto Solder Top-up and Solder Level Detect system. This feature minimizes downtime and ensures consistent solder flow for high-quality results.",
        link: null,
      },
      {
        id: "4",
        key: 3,
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Achieve precise flux application with our Titanium Drop Jet Fluxer, featuring a 200-micron nozzle. This system ensures accurate flux deposition, improving solder joint reliability and reducing overspray and satellites..",
        link: null,
      },
      {
        id: "6",
        key: 4,
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance programming accuracy with our Colour Programming Camera, which offers high-resolution visual feedback. This tool simplifies the setup process, reducing errors and improving efficiency.",
        link: null,
      },
      {
        id: "8",
        key: 5,
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure precise board alignment with our Auto Fiducial Correction System. This feature automatically adjusts for fiducial misalignment, ensuring accurate component placement and soldering in X,Y and theta axes. Our system is capable of utilizing various PCB features beyond traditional fiducial marks.",
        link: null,
      },
      {
        id: "33",
        key: 12,
        name: "Offline Programming Package",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Reduce production downtime with our Offline Programming Package. This system allows you to create and optimize soldering programs without interrupting ongoing production.",
        link: null,
      },
      {
        id: "10",
        key: 6,
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure top-tier soldering performance with our Thermal Nozzle Calibration System, featuring an integrated setting camera. This system guarantees precise nozzle contact location, essential for 1.5mm micro thermal and the latest 1.2mm micro thermal nozzles.",
        link: null,
      },
      {
        id: "12",
        key: 7,
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Monitor and maintain optimal solder wave height with our Solder Wave Height Measurement system. This feature ensures consistent solder quality and reduces defects.",
        link: null,
      },
      {
        id: "13",
        key: 8,
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Gain real-time insights into the soldering process with our Process Viewing Camera. This high-definition camera provides clear visibility, allowing for immediate process adjustments and quality control and has a record function to track performance.",
        link: null,
      },
      {
        id: "16",
        key: 9,
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Equip your machine with our versatile Set of AP Solder Tips, available in various sizes from 2.5mm to 12mm. These tips ensure precise soldering for a wide range of applications.",
        link: null,
      },
      {
        id: "17",
        key: 10,
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Streamline your data analysis with our Standard Production Data Export feature. This system generates configurable CSV files, providing comprehensive board process data for detailed performance review.",
        link: null,
      },
      {
        id: "40",
        key: 13,
        name: "Automatic Electromagnetic Door Locks",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Enhance safety and security with our Automatic Electromagnetic Door Locks. This feature ensures that the machine doors remain securely closed during operation, preventing unauthorized access.",
        link: null,
      },
      {
        id: "41",
        key: 14,
        name: "Automatic Board Side Clamping",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Ensure stable and precise board positioning with our Automatic Board Side Clamping system. This feature prevents board movement during the soldering process, enhancing soldering accuracy.",
        link: null,
      },
      {
        id: "42",
        key: 15,
        name: "Software Intelligent Pre Board Stop Conveyor Sensing",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Optimize board handling with our Software Intelligent Pre Board Stop Conveyor Sensing system. This feature intelligently detects and positions boards for smooth and efficient processing.",
        link: null,
      },
      {
        id: "43",
        key: 16,
        name: "Rapid Bath Interchnage Capability",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Improve flexibility, reduce downtime and increase productivity with our Rapid Bath Interchange Capability. This system allows for quick and easy solder bath changes, ensuring continuous operation.",
        link: null,
      },
      {
        id: "44",
        key: 17,
        name: "Live Monitoring of Machine Downtime via Logfiles",
        icon: "iconLikeCompass",
        available: true,
        description:
          "Keep track of machine performance with our Live Monitoring of Machine Downtime via Logfiles. This feature provides real-time data, helping you identify and address issues promptly to maintain optimal production efficiency.",
        link: null,
      },
    ],
    optional_features: [
      {
        id: 2,
        key: 1,
        name: "Closed-Loop Top Side Pyro Control",
        label_in_configurator: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        available: true,
        description:
          "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
        link: "technology?name=preheating&option=closed_loop_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 17,
        key: 9,
        name: "Automatic Fiducial Correction",
        label_in_configurator: "Fiducial Dynamic Correction",
        icon: "iconCenterPoint",
        available: true,
        description:
          "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
        link: "enhancements?name=other&option=fiducial_correction",
        count: 1,
        editable: true,
        state: true,
      },
      {
        id: 10,
        key: 5,
        name: "Electromagnetic Pump Assembly",
        label_in_configurator: "Electronmagnetic Pump",
        icon: "iconElectromagneticPump",
        available: true,
        description:
          "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation. Enjoy the same great benefits of lower dross and reduced maintenance time.",
        link: "enhancements?name=other&option=electromagnetic_pump",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 24,
        key: 12,
        name: "Nozzle Verification",
        label_in_configurator: "Nozzle Verification",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
        link: "technology?name=soldering&option=nozzle_verfication",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 6,
        name: "Jet Wave or Custom Nozzles",
        label_in_configurator: "Jetwave",
        icon: "iconStreamLineLocation",
        available: true,
        description:
          "The exclusive high flow rate, high thermal mass, low bridging solution. Ideal for connectors and perfect for Military and Aerospace high-reliability processes, it's the best-in-class choice for unmatched performance.",
        link: "enhancements?name=soldering&option=jetwave",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 20,
        key: 10,
        name: "1.2mm Micro Nozzle",
        label_in_configurator: "1.2mm Micro Nozzle",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
        link: "technology?name=soldering&option=1.2mm_micro_nozzle",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 7,
        key: 4,
        name: "PCB Warpage Correction",
        label_in_configurator: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        available: true,
        description:
          "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact. Crucial for micro nozzle technology due to very small flow heights, it outperforms mapping systems by adapting to changes during heating and soldering, handling more than 10mm of movement.",
        link: "enhancements?name=other&option=pcb_warpage_correction",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 3,
        name: "Closed-Loop Bottom Side Pyro Control",
        label_in_configurator: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        available: true,
        description:
          "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
        link: "technology?name=preheating&option=bottom_side_pyro_control",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 2,
        name: "Bottom Side Pre-Heat",
        label_in_configurator: "Bottom Side IR Pre-Heat",
        icon: "iconBottomPreHeat",
        available: true,
        description:
          "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
        link: "technology?name=preheating&option=bottom_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 16,
        key: 8,
        name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
        label_in_configurator: "Drop Jet/Ultrasonic Fluxing",
        icon: "iconHeater",
        available: true,
        description:
          "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
        link: "technology?name=fluxing&option=drop_jet_fluxing",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 7,
        name: "Top Side IR  Pre-Heat",
        label_in_configurator: "Top Side IR Pre-Heat",
        icon: "iconTopPreHeat",
        available: true,
        description:
          "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
        link: "technology?name=preheating&option=top_side_ir",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 22,
        key: 11,
        name: "Thermal Nozzle Calibration",
        label_in_configurator: "Thermal Calibration",
        icon: "iconStreamLineTarget",
        available: true,
        description:
          "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
        link: "technology?name=soldering&option=thermal_calibration",
        count: 0,
        editable: true,
        state: false,
      },
    ],
    other_options: [
      {
        id: 2,
        key: 2,
        name: "Dual Bath",
        label_in_configurator: "Dual Solder Baths",
        available: true,
        description:
          "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
        link: "enhancements?name=other&option=dual_solder_baths",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 5,
        key: 5,
        name: "MES (Manufacturing Equipment Systems)",
        label_in_configurator: "MES (Manufacturing Equipment Systems)",
        available: true,
        description:
          "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
        link: "enhancements?name=other&option=manufacturing_equipment_systems",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 12,
        key: 10,
        name: "Barcode Reader",
        label_in_configurator: "Automatic Program ID and Change",
        available: true,
        description:
          "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
        link: "enhancements?name=other&option=automatic_program_id",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 3,
        key: 3,
        name: "Optical Flux Presence Sensor",
        label_in_configurator: "Optical Flux Presence Sensor",
        available: true,
        description:
          "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
        link: "enhancements?name=fluxing&option=optical_fluc_presence_sensor",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 4,
        key: 4,
        name: "Flux Flow Monitoring",
        label_in_configurator: "Flux Flow Monitoring",
        available: true,
        description:
          "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
        link: "enhancements?name=fluxing&option=flux_flow_monitoring",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 11,
        key: 8,
        name: "Auto Nozzle Tinning",
        label_in_configurator: "Auto Nozzle Tinning",
        available: true,
        description:
          "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
        link: "enhancements?name=soldering&option=auto_nozzle_tinning",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 9,
        key: 7,
        name: "Internal Monitoring of Machine Temperature",
        label_in_configurator: "Internal Monitoring of Machine Temperature",
        available: true,
        description:
          "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
        link: "enhancements?name=other&option=internal_monitoring_temperature",
        count: 0,
        editable: true,
        state: false,
      },
      {
        id: 1,
        key: 1,
        name: "Ring Heater",
        label_in_configurator: "Ring Heater",
        available: false,
        description:
          "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
        link: "technology?name=preheating&option=ring_heater",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 8,
        key: 6,
        name: "Automatic rework Integration",
        label_in_configurator: "Automatic Rework Integration",
        available: false,
        description:
          "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
        link: "enhancements?name=other&option=automatic_rework_integration",
        count: 0,
        editable: false,
        state: false,
      },
      {
        id: 10,
        key: 9,
        name: "Auto Level Detect and Top Up",
        label_in_configurator: "Auto Level Detect and Top Up",
        available: true,
        description:
          "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
        link: "enhancements?name=soldering&option=auto_level_detect",
        count: 5,
        editable: false,
        state: true,
      },
    ],
    configurator: {
      title: "Synchrodex Pro 1600mm Dual Bath Solder Module",
      additional_title: "Large Format Dual XYZ soldering",
      detail_image:
        "assets/images/products/sp1600dbath/SP1600 DBATH RENDER FRONT VIEW AND TOP VIEW.png",
      item_image:
        "assets/images/products/sp1600dbath/SP1600 DBATH RENDER FRONT VIEW.png",
      description:
        "The Synchrodex Pro 1600 Dual Bath Solder Module combines large PCB capability, high throughput, and unparalleled flexibility. Featuring dual independent baths, this advanced system allows for full process adaptability and can efficiently process two boards simultaneously with just a click of the mouse. Additionally, modules can be added to further increase throughput while maintaining a single programming location for all operations.",
    },
    parameters: [
      {
        device_id: 24,
        name: "PCB Size",
        value: "1200mm x 610mm (24” x 45”)",
        order: 1,
      },
      {
        device_id: 24,
        name: "Solder Nozzle Technologies",
        value: "5",
        order: 2,
      },
      {
        device_id: 24,
        name: "Flux Head Orifice",
        value: "100-300 micron",
        order: 3,
      },
      {
        device_id: 24,
        name: "Pre-Heat Capacity",
        value: "8KW",
        order: 4,
      },
      {
        device_id: 24,
        name: "Footprint",
        value: "3.6m2",
        order: 5,
      },
      {
        device_id: 24,
        name: "Solder Temperature Control",
        value: "<= 2 degrees C",
        order: 6,
      },
    ],
  },
];

export const ADAPTED_DEFAULT_PRODUCTS_LIST = DEFAULT_PRODUCTS_LIST?.map(
  (prod, idx) => {
    const cells = prod?.cells?.map(cell => ({
      id: uuidv4(),
      name: cell || null,
      img: null,
    }));
    return {
      ...prod,
      // img: ProductImage,
      id: prod.device_id,
      name: prod.device_name,
      // detailImg: DetailImg,
      standard_features: prod.standard_features,
      optional_features: prod.optional_features.map(feat => ({
        ...feat,
        pointColor: "red",
        key: "red" + feat.key,
      })),
      other_options: prod.other_options.map(opt => ({
        ...opt,
        pointColor: "gray",
        key: "gray" + opt.key,
      })),
      options: [
        {
          id: 1,
          name: "Units Per Hour",
          measurementUnit: `${prod.units_per_hour} u/h`,
          progress: (Number(prod.units_per_hour) / 10) * 100,
        },
        {
          id: 2,
          name: "Fluxes Per Hour",
          measurementUnit: `${prod.fluxes_per_min} f/m`,
          progress: (Number(prod.fluxes_per_min) / 20) * 100,
        },
        {
          id: 3,
          name: "Inclination",
          measurementUnit: `${prod.inclination}°`,
          progress: Number(prod.inclination),
        },
        {
          id: 4,
          name: "Start Time",
          measurementUnit: formatDurationToMinsSeconds(prod.start_time),
          progress: (prod.start_time / 600) * 100,
        },
        {
          id: 5,
          name: "Defects Per 100 Unit",
          measurementUnit: `${prod.defects_per_unit}/100 d`,
          progress: Number(prod.defects_per_unit),
        },
        {
          id: 6,
          name: "Energy Class",
          measurementUnit: "A++",
          progress: 100,
        },
      ],
      cells,
    };
  }
);
