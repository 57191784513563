import { SwiperSlide } from "swiper/react";
import { Pagination, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { InfoBlock } from "../InfoBlock";
import styles from "./CharacteristicsSlider.module.scss";
import { CHARACTERISTICS } from "./constants";
import { MainSwiper } from "shared/components/MainSwiper";

export const CharacteristicsSlider = ({
  contentKey = "default",
  slides = CHARACTERISTICS[contentKey],
}) => {

  return (
    <MainSwiper
      // direction="vertical"
      spaceBetween={30}
      slidesPerView={1}
      autoHeight
      effect={"fade"}
      speed={150}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className}" data-label="${slides[index].section}"></span>`;
        },
      }}
      modules={[Pagination, EffectFade]}
      className={styles.characteristicsVerticalSwiper}
    >
      {slides.map((slide, index) => (
        <SwiperSlide className={styles.swiperItem} key={index}>
          <div className={styles.content}>
            {slide.type === "video" ? (
              <video className={styles.contentVideo} controls>
                <source src={slide.content} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div className={styles.swSlide}>
                <img
                  className={styles.contentImage}
                  src={slide.content}
                  alt={`Slide ${index}`}
                />
              </div>
            )}
            <InfoBlock {...slide} />
          </div>
        </SwiperSlide>
      ))}
    </MainSwiper>
  );
};
