import { useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useTranslation } from "hooks/useTranslation";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import { MainSwiper } from "shared/components/MainSwiper";
import { AddToConfiguratorElm } from "Layouts/AddToConfiguratorElm";
import { BANNER_DETAILS, BANNER_TXT_DETAILS } from "./constants";
import { MainTooltip } from "shared/components/MainTooltip";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import styles from "./Banner.module.scss";
import {
  IconBannerPattern,
  IconPauseTransparent,
  IconPlayTransparent,
} from "icons";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Banner = ({
  detailParameters,
  title,
  bannerDetailKey,
  currentProduct,
  isOpenConfigurator,
  setIsOpenConfigurator,
  isMarketSector,
  prefixLabel,
}) => {
  const { translate } = useTranslation();
  const location = useLocation();

  const [isActiveAutoplay, setIsActiveAutoplay] = useState();
  const swiperRef = useRef(null);
  const { pathname } = location;
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const keyName = BANNER_DETAILS.hasOwnProperty(name?.toLowerCase())
    ? name?.toLowerCase()
    : "default";
  const isProductPage =
    pathname.includes("/product") || pathname.includes("/market");

  // const bannerDetailsKey = ROUTES_WITH_BANNER_IMAGES.includes(keyName)
  //   ? keyName
  //   : isMarketSector
  //     ? "market"
  //     : "default";

  // HIDDEN MIGHT BE USED THEN
  const paginationIcons = {
    // 0: ReactDOMServer.renderToString(
    //   <IconSlideInfo className={styles.infoBullet} />
    // ),
    // 1: ReactDOMServer.renderToString(
    //   <IconSlideLocation className={styles.locationBullet} />
    // ),
  };

  const handleToggleAutoplay = () => {
    if (swiperRef.current.autoplay.running) {
      swiperRef.current.autoplay.stop();
      setIsActiveAutoplay(false);
    } else {
      swiperRef.current.autoplay.start();
      setIsActiveAutoplay(true);
    }
  };

  return (
    <div className={styles.swipeBannerBlock}>
      <MainTooltip
        title={translate("Autoplay")}
        color="black"
        padding="12px 16px"
        fontSize="14px"
        fontWeight="400"
      >
        <div className={styles.playPauseElm} onClick={handleToggleAutoplay}>
          {isActiveAutoplay ? (
            <IconPauseTransparent />
          ) : (
            <IconPlayTransparent />
          )}
        </div>
      </MainTooltip>
      <MainSwiper
        ref={swiperRef}
        direction={"vertical"}
        effect={"fade"}
        speed={150}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            const iconHtml = paginationIcons[index];
            if (iconHtml) {
              return `<span class="${className} slider-custom-icon">${iconHtml}</span>`;
            }
            return `<span class="${className}"></span>`;
          },
        }}
        modules={[Autoplay, Pagination, EffectFade]}
        className={styles.bannerSwiper}
        autoplay={false}
      >
        {!!currentProduct && (
          <AddToConfiguratorElm
            isOpenConfigurator={isOpenConfigurator}
            setIsOpenConfigurator={setIsOpenConfigurator}
            currentProduct={currentProduct}
          />
        )}
        {BANNER_DETAILS[keyName]?.map((bannerDetail, idx) => {
          return (
            <SwiperSlide key={keyName + idx}>
              <div className={styles.container}>
                {bannerDetail.backgroundImg.endsWith("mp4") ? (
                  <video
                    className={styles.bannerImage}
                    src={bannerDetail.backgroundImg}
                    autoPlay
                    loop
                    muted={true}
                  />
                ) : (
                  <img
                    className={styles.bannerImage}
                    src={bannerDetail.backgroundImg}
                    alt={bannerDetail.title}
                  />
                )}

                <div className={styles.overlayBlock} />
              </div>
            </SwiperSlide>
          );
        })}
      </MainSwiper>
      <div className={styles.contentBlock}>
        {isProductPage && (
          <div className={styles.detailParametersBlock}>
            {Object.entries(
              (
                BANNER_TXT_DETAILS[keyName] ||
                BANNER_TXT_DETAILS[bannerDetailKey]
              ).details
            ).map((param, idx) => {
              const desc = param[0];
              const value = param[1];
              return (
                <div key={keyName + idx} className={styles.param}>
                  <span className={styles.paramValue}>{value}</span>
                  <span className={styles.paramDesc}>{desc}</span>
                </div>
              );
            })}
          </div>
        )}
        <div className={styles.titleBlock}>
          <IconBannerPattern className={styles.patternIcon} />
          {bannerDetailKey === "market" ? (
            <p className={styles.sectorText}>{translate("Market Sector")}:</p>
          ) : (
            <>
              {!!prefixLabel && (
                <p className={styles.sectorText}>{prefixLabel}:</p>
              )}
            </>
          )}
          <p className={styles.bannerTitle}>{title}</p>
        </div>
      </div>
    </div>
  );
};
