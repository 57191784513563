import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { apiClient } from "services/api";
import { PRODUCT_CELLS } from "../mockData";
import { formatDurationToMinsSeconds } from "utils/dateUtils";
import ProductImage from "assets/images/products/productImg.png";
import DetailImg from "assets/images/products/productDetailImg.png";
import { ADAPTED_DEFAULT_PRODUCTS_LIST } from "constants";

export const fetchDeviceListAsync = createAsyncThunk(
  "configurator/fetchDeviceList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get("/device/list");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const chosenProductsSelector = state =>
  state.configurator.chosenProducts;

const initialState = {
  productsList: ADAPTED_DEFAULT_PRODUCTS_LIST,
  onlyConfigProducts: ADAPTED_DEFAULT_PRODUCTS_LIST.filter(
    prod => prod.add_to_configurator
  ),
  cellsList: PRODUCT_CELLS,
  chosenProducts: [],
  selectedLineItem: null,
  selectedCellToView: null,
};

const configuratorSlice = createSlice({
  name: "configurator",
  initialState,
  reducers: {
    addProduct: state => {
      const newItem = {
        lineItemId: uuidv4(),
        product: null,
        cells: [],
      };
      state.chosenProducts.push(newItem);
    },
    deleteProductById: (state, action) => {
      const { id } = action.payload;
      state.chosenProducts = state.chosenProducts.filter(
        prod => prod.lineItemId !== id
      );
    },
    selectProduct: (state, action) => {
      const { lineItemId, product, cellsCount, cells } = action.payload;
      const item = state.chosenProducts.find(
        item => item.lineItemId === lineItemId
      );
      if (item) {
        item.product = product;
        // Initialize the cells array with the appropriate length
        item.cells = new Array(cellsCount).fill(null);
        if (cells && cells.length) {
          // Cycle through the provided cells and match with PRODUCT_CELLS
          item.cells = cells.map(cell => {
            // Find the matching product cell by backend_name
            const foundCell = PRODUCT_CELLS.find(
              productCell =>
                productCell.find_name === cell?.name ||
                productCell.backend_name === cell?.name
            );
            return foundCell || null;
          });
        }
      }
    },
    addNewProductWithSelect: (state, action) => {
      const newItem = {
        lineItemId: uuidv4(),
        product: null,
        cells: [],
      };
      const { product, cellsCount, cells } = action.payload;

      newItem.product = product;

      if (cellsCount) {
        newItem.cells = new Array(cellsCount).fill(null);
      }

      if (cells && cells.length) {
        newItem.cells = cells.map(cell => {
          const foundCell = PRODUCT_CELLS.find(
            productCell =>
              productCell.find_name === cell?.name ||
              productCell.backend_name === cell?.name
          );
          return foundCell || null;
        });
      }

      state.chosenProducts.push(newItem);
    },
    addCellToProduct: (state, action) => {
      const { lineItemId, selectedCell, idx, isDuplicateFromFirst } =
        action.payload;
      const productIndex = state.chosenProducts.findIndex(
        item => item.lineItemId === lineItemId
      );

      if (productIndex !== -1) {
        const product = state.chosenProducts[productIndex];
        if (product.product && !product.cells[idx]) {
          const newCells = product.cells.map((cell, cellIdx) => {
            if (cellIdx === idx) {
              return {
                ...selectedCell,
                id: uuidv4(),
                optional_features: isDuplicateFromFirst
                  ? product.cells[0]?.optional_features
                  : product.product.optional_features,
                other_options: isDuplicateFromFirst
                  ? product.cells[0]?.other_options
                  : product.product.other_options,
                isDuplicateFromFirst,
              };
            }
            return cell;
          });

          state.chosenProducts[productIndex].cells = newCells;
          if (
            state.selectedLineItem &&
            state.selectedLineItem.lineItemId === lineItemId
          ) {
            state.selectedLineItem.cells = newCells;
          }
        }

        if (idx === 0) {
          const updatedCells = product.cells.map((cell, cellIdx) => {
            if (cellIdx !== 0 && product.cells[cellIdx]?.isDuplicateFromFirst) {
              return {
                ...product.cells[0],
                id: uuidv4(),
                optional_features: product.product.optional_features,
                other_options: product.product.other_options,
              };
            }
            return cell;
          });

          state.chosenProducts[productIndex].cells = updatedCells;
          if (
            state.selectedLineItem &&
            state.selectedLineItem.lineItemId === lineItemId
          ) {
            state.selectedLineItem.cells = updatedCells;
          }
        }
      }
    },

    setOptionalFeaturesForLineItem: (state, action) => {
      const {
        newCheckedValue,
        selectedLineItem,
        optionalFeature,
        resetValue,
        setCountToOne,
      } = action.payload;

      const lineItemIndex = state.chosenProducts.findIndex(
        item => item.lineItemId === selectedLineItem.lineItemId
      );

      if (lineItemIndex !== -1) {
        const chosenProducts = state.chosenProducts;
        const updatedOptionalFeatures = chosenProducts[
          lineItemIndex
        ]?.product?.optional_features.map(feature => {
          if (feature?.id === optionalFeature?.id) {
            if (resetValue) feature.count = 0;
            if (setCountToOne) feature.count = 1;
            return { ...feature, state: newCheckedValue };
          }
          return feature;
        });
        if (state.chosenProducts[lineItemIndex]?.product?.optional_features) {
          state.selectedLineItem.product.optional_features =
            updatedOptionalFeatures;
          state.chosenProducts[lineItemIndex].product.optional_features =
            updatedOptionalFeatures;
        }
      }
    },

    incrementOptionalFeatureCountForLineItem: (state, action) => {
      const { count, selectedLineItem, optionalFeature } = action.payload;

      const lineItemIndex = state.chosenProducts.findIndex(
        item => item.lineItemId === selectedLineItem.lineItemId
      );

      if (lineItemIndex !== -1) {
        const updatedOptionalFeatures = state.chosenProducts[
          lineItemIndex
        ].product.optional_features.map(feature => {
          if (feature.id === optionalFeature.id) {
            return {
              ...feature,
              count: (count || 0) + 1,
            };
          }
          return feature;
        });

        state.chosenProducts[lineItemIndex].product.optional_features =
          updatedOptionalFeatures;

        if (
          state.selectedLineItem &&
          state.selectedLineItem.lineItemId === selectedLineItem.lineItemId
        ) {
          state.selectedLineItem.product.optional_features =
            updatedOptionalFeatures;
        }
      }
    },

    decrementOptionalFeatureCountForLineItem: (state, action) => {
      const { count, selectedLineItem, optionalFeature } = action.payload;

      const lineItemIndex = state.chosenProducts.findIndex(
        item => item.lineItemId === selectedLineItem.lineItemId
      );

      if (lineItemIndex !== -1) {
        const updatedOptionalFeatures = state.chosenProducts[
          lineItemIndex
        ].product.optional_features.map(feature => {
          if (feature.id === optionalFeature.id) {
            return {
              ...feature,
              count: count > 1 ? count - 1 : count,
            };
          }
          return feature;
        });

        state.chosenProducts[lineItemIndex].product.optional_features =
          updatedOptionalFeatures;

        if (
          state.selectedLineItem &&
          state.selectedLineItem.lineItemId === selectedLineItem.lineItemId
        ) {
          state.selectedLineItem.product.optional_features =
            updatedOptionalFeatures;
        }
      }
    },

    setOtherOptionForLineItem: (state, action) => {
      const { newCheckedValue, selectedLineItem, otherOption } = action.payload;
      const lineItemIndex = state.chosenProducts.findIndex(
        item => item?.lineItemId === selectedLineItem?.lineItemId
      );
      if (lineItemIndex !== -1) {
        const chosenProducts = state.chosenProducts;
        const updatedOptionalFeatures = chosenProducts[
          lineItemIndex
        ]?.product?.other_options.map(feature => {
          if (feature?.id === otherOption?.id) {
            return { ...feature, state: newCheckedValue };
          }
          return feature;
        });
        if (state.chosenProducts[lineItemIndex]?.product?.other_options) {
          state.selectedLineItem.product.other_options =
            updatedOptionalFeatures;
          state.chosenProducts[lineItemIndex].product.other_options =
            updatedOptionalFeatures;
        }
      }
    },

    resetConfigurator: state => {
      state.chosenProducts = [];
      state.selectedCellToView = null;
      state.selectedLineItem = null;
    },
    setSelectedLineItem: (state, action) => {
      state.selectedLineItem = action.payload;
    },
    setSelectedCellToView: (state, action) => {
      if (action.payload === null) {
        state.selectedCellToView = action.payload;
      } else {
        const { id, index } = action.payload;
        const currentCell = state.selectedLineItem.cells.find(
          cell => cell?.id === id
        );
        state.selectedCellToView = { ...(currentCell || {}), index };
      }
    },
    updatePersistKey: (state, action) => {
      // This reducer can remain empty as we only use it to trigger the store update
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDeviceListAsync.fulfilled, (state, action) => {
        const productList = action.payload?.map((prod, idx) => {
          const cells = prod?.cells?.map(cell => ({
            id: uuidv4(),
            name: cell || null,
            img: null,
          }));
          return {
            ...prod,
            img: ProductImage,
            id: prod.device_id,
            name: prod.device_name,
            detailImg: DetailImg,
            standard_features: prod.standard_features,
            optional_features: prod.optional_features.map(feat => ({
              ...feat,
              pointColor: "red",
              key: "red" + feat.key,
            })),
            other_options: prod.other_options.map(opt => ({
              ...opt,
              pointColor: "gray",
              key: "gray" + opt.key,
            })),
            options: [
              {
                id: 1,
                name: "Units Per Hour",
                measurementUnit: `${prod.units_per_hour} u/h`,
                progress: (Number(prod.units_per_hour) / 10) * 100,
              },
              {
                id: 2,
                name: "Fluxes Per Hour",
                measurementUnit: `${prod.fluxes_per_min} f/m`,
                progress: (Number(prod.fluxes_per_min) / 20) * 100,
              },
              {
                id: 3,
                name: "Inclination",
                measurementUnit: `${prod.inclination}°`,
                progress: Number(prod.inclination),
              },
              {
                id: 4,
                name: "Start Time",
                measurementUnit: formatDurationToMinsSeconds(prod.start_time),
                progress: (prod.start_time / 600) * 100,
              },
              {
                id: 5,
                name: "Defects Per 100 Unit",
                measurementUnit: `${prod.defects_per_unit}/100 d`,
                progress: Number(prod.defects_per_unit),
              },
              {
                id: 6,
                name: "Energy Class",
                measurementUnit: "A++",
                progress: 100,
              },
            ],
            cells,
          };
        });
        state.onlyConfigProducts = productList.filter(
          prod => prod.add_to_configurator
        );
        state.productsList = productList;
      })
      .addCase(fetchDeviceListAsync.rejected, (state, action) => {
        console.error("action.payload", action.payload);
      });
  },
});

export const {
  addProduct,
  deleteProductById,
  selectProduct,
  addCellToProduct,
  resetConfigurator,
  setSelectedLineItem,
  setSelectedCellToView,
  setOptionalFeaturesForLineItem,
  setOtherOptionForLineItem,
  addNewProductWithSelect,
  incrementOptionalFeatureCountForLineItem,
  decrementOptionalFeatureCountForLineItem,
  updatePersistKey,
} = configuratorSlice.actions;

export default configuratorSlice.reducer;
