import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styles from "./OptionCheckLine.module.scss";

export const OptionCheckLine = props => {
  const {
    label,
    defaultChecked,
    lineWidth,
    disabled,
    isEmpty,
    checked,
    onToggle,
    autoWidth,
  } = props;
  const handleToggle = event => {
    onToggle(event.target.checked);
  };

  return (
    <div
      className={`${styles.container} ${isEmpty && styles.empty} ${
        autoWidth && styles.autoWidth
      }`}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={isEmpty && defaultChecked}
              checked={checked}
              disabled={disabled}
              onChange={handleToggle}
            />
          }
          label={label}
        />
      </FormGroup>
      {!!isEmpty && (
        <span className={styles.rowLine} style={{ width: lineWidth }}></span>
      )}
    </div>
  );
};
