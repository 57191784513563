import { useTranslation } from "hooks/useTranslation";
import { DynamicLink } from "../DynamicLink";
import styles from "./ProductItem.module.scss";

export const ProductItem = ({
  content,
  device_options_image,
  name,
  description,
  forwardTo = "",
}) => {
  const { translate } = useTranslation();

  return (
    <DynamicLink to={forwardTo} className={styles.container}>
      <div className={styles.imgBlock}>
        <img alt={name} src={content || device_options_image} />
      </div>
      <h6 className={styles.name}>{translate(name)}</h6>
      <p className={styles.desc}>{description}</p>
    </DynamicLink>
  );
};
