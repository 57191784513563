import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "hooks/useTranslation";
import {
  EXISTING_ENHANCEMENTS_KEYS,
  EXISTING_KEYS,
  FEATURES_INFOS,
  PRODUCT_SLIDES,
  PRODUCTS_WITH_SLIDER_KEYS,
  IMAGE_BASE_URL,
  RELATED_PRODUCTS,
} from "./constants";
import { FeatureItem } from "./components/FeatureItem";
import { InformativeBlock } from "shared/components/InformativeBlock";
import { HorizontalProductSwipe } from "shared/components/HorizontalProductSwipe";
import { ProductItem } from "shared/components/ProductItem";
import { SectionTitleLeftWaves } from "shared/components/SectionTitleLeftWaves";
import { TransparentDialog } from "shared/components/TransparentDialog";
import styles from "./Product.module.scss";
import { IconThreeWavesToRight } from "icons";
import { useSelector } from "react-redux";
import { productsListSelector } from "reduxStore/slices/selectors";
import { OPTIONAL_FEATURES_BY_KEY } from "./constants";
import { shuffleArray } from "utils/arrayUtils";

export const Product = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const name = queryParams.get("name");
  const productsList = useSelector(productsListSelector);
  const chosenItemName =
    queryParams.get("option") && queryParams.get("option").replaceAll("_", " ");

  const firstColIds = [2, 13, 6, 33, 41, 8, 12, 27];
  const secondColIds = [10, 42, 44, 40, 3, 43, 4, 16, 17];

  const relatedProductNames = RELATED_PRODUCTS[name] || [];

  const relatedProducts = productsList.filter(product =>
    relatedProductNames.includes(product.device_name)
  );

  const currentProduct = productsList.find(prod => {
    return prod.device_name?.toLowerCase() === name?.toLowerCase();
  });

  const findFeatureColumn = useCallback(
    idsArr => {
      return currentProduct?.standard_features?.filter(({ id }) =>
        idsArr.includes(+id)
      );
    },
    [currentProduct?.standard_features]
  );

  const [defaultActiveSwiperIdx, setDefaultActiveSwiperIdx] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const horizontalSwiperRef = useRef(null);

  const { translate } = useTranslation();

  const contentKey = EXISTING_KEYS[name] || "default";
  const enhancementsContentKey = EXISTING_ENHANCEMENTS_KEYS[name] || "default";
  const productSliderKey = PRODUCTS_WITH_SLIDER_KEYS[name] || "default";

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (name && chosenItemName) {
      horizontalSwiperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      const selectingSwiperItemIdx = PRODUCT_SLIDES[
        enhancementsContentKey
      ].findIndex(
        item => item?.name?.toLowerCase() === chosenItemName?.toLowerCase()
      );
      setDefaultActiveSwiperIdx(selectingSwiperItemIdx);
      navigate(location.pathname + location.search, {
        replace: true,
        state: {},
      });
    }
  }, [
    name,
    chosenItemName,
    enhancementsContentKey,
    navigate,
    location.pathname,
    location.search,
  ]);

  return (
    <div className={styles.container}>
      {currentProduct?.standard_features?.length > 0 && (
        <div className={styles.featuresBlock}>
          <div className={styles.features}>
            <h1 className={styles.title}>
              {translate("Standard Features")}
              <IconThreeWavesToRight className={styles.titleIcon} />
            </h1>
            <div className={styles.features_block}>
              <div className={styles.features_column}>
                {findFeatureColumn(firstColIds)?.map((feat, idx) => {
                  if (!feat.available) return null;

                  return (
                    <FeatureItem
                      key={idx}
                      enabled={feat.available}
                      text={feat.name}
                      standardFeature
                    />
                  );
                })}
              </div>
              <div className={styles.features_column}>
                {findFeatureColumn(secondColIds)?.map((feat, idx) => {
                  if (!feat.available) return null;

                  return (
                    <FeatureItem
                      key={idx}
                      enabled={feat.available}
                      text={feat.name}
                      standardFeature
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.infos}>
        {FEATURES_INFOS[contentKey].map((info, idx) => (
          <InformativeBlock key={idx} text={info} />
        ))}
      </div>
      {currentProduct && (
        <div className={styles.systemOptions}>
          <div className={styles.prodImgContainer} onClick={openModal}>
            <img
              className={styles.productImg}
              src={
                currentProduct?.device_options_image ||
                `${IMAGE_BASE_URL}products/fusion-4.png`
              }
              alt="product"
            />
          </div>
          {[
            ...currentProduct?.optional_features,
            ...currentProduct?.other_options,
          ].map((opt, idx) => {
            const currentOpt = {
              ...OPTIONAL_FEATURES_BY_KEY[opt?.key],
              ...opt,
            };

            const forwardTo = opt.link;

            return (
              <FeatureItem
                {...currentOpt}
                pointColor={opt.pointColor}
                enabled={opt.available}
                key={idx}
                forwardTo={forwardTo}
                text={currentOpt.name}
              />
            );
          })}
        </div>
      )}
      <div ref={horizontalSwiperRef} className={styles.productSlideSec}>
        <HorizontalProductSwipe
          defaultActiveSwiperIdx={defaultActiveSwiperIdx}
          slides={PRODUCT_SLIDES[productSliderKey]}
        />
      </div>

      <div className={styles.section}>
        <SectionTitleLeftWaves title={translate("Related Products")} />
        <div className={styles.otherChoiceContent}>
          {relatedProducts.map((item, idx) => (
            <ProductItem
              key={idx}
              name={item.device_name}
              content={item.device_options_image}
              forwardTo={`/product?name=${item.device_name}`}
            />
          ))}
        </div>
      </div>

      {currentProduct && (
        <TransparentDialog isOpen={isModalOpen} onClose={closeModal}>
          <img
            className={styles.productImgInModal}
            src={currentProduct?.device_options_image}
            alt="Product"
          />
        </TransparentDialog>
      )}
    </div>
  );
};
