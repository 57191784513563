import { Link, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { Tooltip } from "shared/components/Tooltip";

export const DynamicLink = ({
  to,
  queryParams,
  onClick,
  disabled,
  isDisabled,
  tooltipText = "Coming Soon",
  ...props
}) => {
  const { company } = useParams();
  const navigate = useNavigate();

  const basePath = company ? `/${company}${to}` : to;

  const queryStringified = queryParams
    ? `?${queryString.stringify(queryParams)}`
    : "";
  const fullPath = `${basePath}${queryStringified}`;

  const handleClick = event => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    event.preventDefault();
    window.scrollTo(0, 0);
    if (typeof onClick === "function") {
      onClick(event);
    }
    const basePath = company ? `/${company}${to}` : to;
    const queryStringified = queryParams
      ? `?${queryString.stringify(queryParams)}`
      : "";
    const fullPath = `${basePath}${queryStringified}`;
    navigate(fullPath);
  };

  if (isDisabled) {
    return (
      <Tooltip title={tooltipText} placement="bottom">
        <span
          className={
            props.className ? `${props.className} disabled` : "disabled"
          }
          style={{ cursor: "not-allowed", opacity: 0.6 }}
        >
          {props.children}
        </span>
      </Tooltip>
    );
  }

  return <Link to={fullPath} {...props} onClick={e => handleClick(e)} />;
};
