import {
  IconStarFilled,
  IconDoubleTickFilled,
  IconDiamondFilled,
  IconSettingFilled,
  IconWavedLampFilled,
  IconPrecisionFilled,
} from "icons";

// Update the image imports to use the base URL from the environment variable
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

export const EXISTING_MARKET_SECTOR_KEYS = {
  "automotive/ev": "automotive/ev",
  medical: "medical",
  aerospace: "aerospace",
};

export const INFOS = {
  "automotive/ev": [
    "Introducing Pillarhouse, your trusted partner in soldering excellence for the automotive industry. As pioneers in soldering technology, we specialize in providing top-tier soldering services tailored specifically for manufacturers across a wide spectrum of automotive applications. From battery management systems to power electronics and beyond, we offer comprehensive soldering solutions to meet the diverse needs of automotive production.",
    "Whether you're embarking on prototype development, managing low-volume production, or scaling up for high-volume manufacturing, Pillarhouse has the capabilities and resources to effectively support your needs.",
    "As a valued partner to leading automotive manufacturers and suppliers, we are dedicated to achieving excellence in every facet of our operations. By prioritizing innovation, quality, and customer satisfaction, we aim not only to meet but exceed expectations in the dynamic and evolving automotive market.",
  ],
  medical: [
    "From implantable devices to diagnostic equipment and beyond, we offer comprehensive soldering services for a wide range of medical device applications. Whether you require prototype development, low-volume production, or high-volume manufacturing, we have the capabilities and resources to meet your needs with precision, control, and reliability.",
    "As a trusted partner to leading medical device manufacturers and suppliers, we are committed to excellence in every aspect of our work. With a focus on precision, meticulous tracking, and unwavering reliability, we strive to exceed expectations and drive success in the highly regulated medical device market.",
    "Introducing Pillarhouse, your trusted partner in soldering excellence for the medical device industry. As pioneers in soldering technology, we specialize in providing top-tier soldering services tailored specifically for medical device manufacturers, ensuring the highest standards of precision, control, and reliability.",
  ],
  aerospace: [
    "From aerospace control modules with ARINC connectors to sensitive touchscreen and push-button panels, Pillarhouse delivers comprehensive soldering solutions for the most demanding industries. Whether you're focused on prototype development, low-volume production, or high-volume manufacturing, our cutting-edge technology ensures unmatched precision, control, and reliability for every application.",
    "As a trusted partner to leading manufacturers in aerospace, medical, and other high-reliability industries, Pillarhouse is committed to excellence at every level. With advanced process controls, meticulous tracking systems, and unwavering dedication to quality, we go beyond expectations to drive success in highly regulated and performance-critical markets.",
    "Introducing Pillarhouse, the global leader in selective soldering technology. With innovative features like active warpage correction, micro-thermal nozzles for tight keep-out zones, and closed-loop thermal management, our systems are designed to meet the unique challenges of industries that demand precision, reliability, and excellence in every solder joint.",
  ],
};

export const SYSTEM_OPTIONS = {
  default: [
    {
      name: "Top Side IR  Pre-Heat",
      link: "technology?name=preheating&option=top_side_ir",
    },
    {
      name: "Motorised underside IR preheat, at all cell positions",
      link: "technology?name=preheating&option=top_side_ir",
    },
    {
      name: "Ultrasonic fluxing",
      link: "technology?name=fluxing&option=drop_jet_fluxing",
    },
    {
      name: "Drop Jet -multiple heads",
      link: "technology?name=fluxing&option=drop_jet_fluxing",
    },
  ],
  "automotive/ev": [
    {
      name: "Top Side IR  Pre-Heat",
      link: "technology?name=preheating&option=top_side_ir",
    },
    {
      name: "Motorised underside IR preheat, at all cell positions",
      link: "technology?name=preheating&option=top_side_ir",
    },
    {
      name: "Ultrasonic fluxing",
      link: "technology?name=fluxing&option=drop_jet_fluxing",
    },
    {
      name: "Drop Jet -multiple heads",
      link: "technology?name=fluxing&option=drop_jet_fluxing",
    },
  ],
  medical: [
    {
      name: "Top Side IR  Pre-Heat",
      link: "technology?name=preheating&option=top_side_ir",
    },
    {
      name: "Closed-Loop Top Side Pyro Control",
      link: "technology?name=preheating&option=closed_loop_control",
    },
    {
      name: "Bottom Side Pre-Heat",
      link: "technology?name=preheating&option=bottom_side_ir",
    },
    {
      name: "Closed-Loop Bottom Side Pyro Control",
      link: "technology?name=preheating&option=bottom_side_pyro_control",
    },
    {
      name: "Drop Jet -multiple heads",
      link: "technology?name=fluxing&option=drop_jet_fluxing",
    },
    {
      name: "Automatic Fiducial Correction",
      link: "enhancements?name=other&option=fiducial_correction",
    },
    {
      name: "PCB Warpage Correction",
      link: "enhancements?name=other&option=pcb_warpage_correction",
    },
    {
      name: "1.2mm Micro Nozzle",
      link: "technology?name=soldering&option=1.2mm_micro_nozzle",
    },
    {
      name: "Thermal Nozzle Calibratio",
      link: "technology?name=soldering&option=thermal_calibration",
    },
    {
      name: "Nozzle Verification",
      link: "technology?name=soldering&option=nozzle_verfication",
    },
  ],
  aerospace: [
    {
      name: "Closed-Loop Preheat Control",
      link: "technology?name=preheating&option=closed_loop_control",
    },
    {
      name: "Full Convection Heat",
      link: "enhancements?name=other&option=automatic_rework_integration",
    },
    {
      name: "Jetwave nozzle technology",
      link: "enhancements?name=soldering&option=jetwave",
    },
    {
      name: "Multiple Flux heads-Ultrasonics",
      link: "technology?name=fluxing&option=drop_jet_fluxing",
    },
    {
      name: "Automatic Fiducial Correction",
      link: "enhancements?name=other&option=fiducial_correction",
    },
    {
      name: "PCB Warpage Correction",
      link: "enhancements?enhancements?name=other&option=pcb_warpage_correction",
    },
  ],
};

export const MONITORING_OPTIONS = {
  "automotive/ev": [
    {
      name: "Flux spray monitoring",
      link: "enhancements?name=fluxing&option=flux_flow_monitoring",
    },
    {
      name: "Flux Flow Monitoring",
      link: "enhancements?name=fluxing&option=flux_flow_monitoring",
    },
    {
      name: "Flux spray & flow monitoring",
      link: "enhancements?name=fluxing&option=flux_flow_monitoring",
    },
  ],
  medical: [
    {
      name: "Optical Flux Presence Sensor",
      link: "enhancements?name=fluxing&option=optical_flux_presence_sensor",
    },
    {
      name: "Flux Flow Monitoring",
      link: "enhancements?name=fluxing&option=flux_flow_monitoring",
    },
    {
      name: "MES (Manufacturing Equipment Systems)",
      link: "enhancements?name=other&option=manufacturing_equipment_systems",
    },
    {
      name: "Automatic rework Integration",
      link: "enhancements?name=other&option=automatic_rework_integration",
    },
    {
      name: "Internal Monitoring of Machine Temperature",
      link: "enhancements?name=other&option=internal_monitoring_temperature",
    },
  ],
  aerospace: [
    {
      name: "Auto flux purge and cleaning system",
      link: "enhancements?name=soldering&option=auto_level_detect",
    },
    {
      name: "MES (Manufacturing Equipment Systems)",
      link: "enhancements?name=other&option=manufacturing_equipment_systems",
    },
    {
      name: "Automatic rework Integration",
      link: "enhancements?name=other&option=automatic_rework_integration",
    },
    {
      name: "Internal Monitoring of Machine temperature",
      link: "enhancements?name=other&option=internal_monitoring_temperature",
    },
  ],
};

export const CHOOSE_US_ITEMS = {
  "automotive/ev": [
    {
      icon: <IconStarFilled />,
      title: "Expertise",
      text: "Experience meets innovation in Pillarhouse, your go-to partner for cutting-edge solutions in the selective soldering industry. With roots deeply embedded in the automotive sector, we've been trusted by major EV and traditional automakers worldwide.",
    },
    {
      icon: <IconPrecisionFilled />,
      title: "Precision",
      text: "Powered by state-of-the-art process control technology, we ensure a robust manufacturing process that stands the test of time, from inception to mature production.",
    },
    {
      icon: <IconDoubleTickFilled />,
      title: "Efficiency",
      text: "Efficiency is at the core of what we do. Whether you opt for our modular or cellular solutions, rest assured, we have the perfect streamlined system tailored to your exact requirements.",
    },
    {
      icon: <IconWavedLampFilled />,
      title: "Innovation",
      text: "At Pillarhouse, innovation isn't just a buzzword – it's our ethos. With 'Expect Innovation' as our tagline, we're dedicated to delivering on that promise. As the largest market share 'pure play' selective manufacturer, we channel all our resources into driving process advancements that set industry standards.",
    },
    {
      icon: <IconDiamondFilled />,
      title: "Quality",
      text: "With multiple factories and mature manufacturing processes, Pillarhouse guarantees the highest standard of machinery and processes, ensuring reliability and longevity.",
    },
    {
      icon: <IconSettingFilled />,
      title: "Customization",
      text: "With a keen ear to your needs, Pillarhouse offers tailored solutions that are both agile and creative. Explore our diverse range of platforms, each uniquely suited to elevate your industry standards. Trust Pillarhouse for unmatched expertise, precision, efficiency, innovation, quality, and customization – because your success is our priority.",
    },
  ],
  medical: [
    {
      icon: <IconStarFilled />,
      title: "Expertise",
      text: "Experience meets innovation in Pillarhouse, your go-to partner for cutting-edge solutions in the selective soldering industry. With roots deeply embedded in the automotive sector, we've been trusted by major EV and traditional automakers worldwide.",
    },
    {
      icon: <IconPrecisionFilled />,
      title: "Precision",
      text: "Powered by state-of-the-art process control technology, we ensure a robust manufacturing process that stands the test of time, from inception to mature production.",
    },
    {
      icon: <IconDoubleTickFilled />,
      title: "Efficiency",
      text: "Efficiency is at the core of what we do. Whether you opt for our modular or cellular solutions, rest assured, we have the perfect streamlined system tailored to your exact requirements.",
    },
    {
      icon: <IconWavedLampFilled />,
      title: "Innovation",
      text: "At Pillarhouse, innovation isn't just a buzzword – it's our ethos. With 'Expect Innovation' as our tagline, we're dedicated to delivering on that promise. As the largest market share 'pure play' selective manufacturer, we channel all our resources into driving process advancements that set industry standards.",
    },
    {
      icon: <IconDiamondFilled />,
      title: "Quality",
      text: "With multiple factories and mature manufacturing processes, Pillarhouse guarantees the highest standard of machinery and processes, ensuring reliability and longevity.",
    },
    {
      icon: <IconSettingFilled />,
      title: "Customization",
      text: "With a keen ear to your needs, Pillarhouse offers tailored solutions that are both agile and creative. Explore our diverse range of platforms, each uniquely suited to elevate your industry standards. Trust Pillarhouse for unmatched expertise, precision, efficiency, innovation, quality, and customization – because your success is our priority.",
    },
  ],
  aerospace: [
    {
      icon: <IconStarFilled />,
      title: "Expertise",
      text: "Experience meets innovation in Pillarhouse, your go-to partner for cutting-edge solutions in the selective soldering industry. With roots deeply embedded in the automotive sector, we've been trusted by major EV and traditional automakers worldwide.",
    },
    {
      icon: <IconPrecisionFilled />,
      title: "Precision",
      text: "Powered by state-of-the-art process control technology, we ensure a robust manufacturing process that stands the test of time, from inception to mature production.",
    },
    {
      icon: <IconDoubleTickFilled />,
      title: "Efficiency",
      text: "is at the core of what we do. Whether you opt for our modular or cellular solutions, rest assured, we have the perfect streamlined system tailored to your exact requirements.",
    },
    {
      icon: <IconWavedLampFilled />,
      title: "Innovation",
      text: 'At Pillarhouse, innovation isn\'t just a buzzword – it\'s our ethos. With "Expect Innovation" as our tagline, we\'re dedicated to delivering on that promise. As the largest market share "pure play" selective manufacturer, we channel all our resources into driving process advancements that set industry standards.',
    },
    {
      icon: <IconDiamondFilled />,
      title: "Quality",
      text: "With multiple factories and mature manufacturing processes, Pillarhouse guarantees the highest standard of machinery and processes, ensuring reliability and longevity.",
    },
    {
      icon: <IconSettingFilled />,
      title: "Customization",
      text: "With a keen ear to your needs, Pillarhouse offers tailored solutions that are both agile and creative. Explore our diverse range of platforms, each uniquely suited to elevate your industry standards. Trust Pillarhouse for unmatched expertise, precision, efficiency, innovation, quality, and customization – because your success is our priority.",
    },
  ],
};

export const OTHER_MARKETS = [
  {
    name: "Medical",
    forwardTo: "/market?name=Medical",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: `${IMAGE_BASE_URL}market-sector/medical.png`,
  },
  {
    name: "Aerospace",
    forwardTo: "/market?name=Aerospace",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: `${IMAGE_BASE_URL}market-sector/aviation.png`,
  },
  {
    name: "Contract Manufacturing",
    forwardTo: "/market?name=Hi-Rel%2FInfrastructure",
    description:
      "Enhanced, entry-level, single point selective soldering system",
    content: `${IMAGE_BASE_URL}market-sector/contract-manufacturing.png`,
    isDisabled: true,
  },
];

export const GENERAL_DESC_BY_KEY = {
  default:
    "Welcome to Pillarhouse, your premier partner in soldering solutions for the dynamic electric vehicle (EV) industry. With unparalleled expertise and cutting-edge technology, we specialize in providing high-quality soldering services tailored specifically to the unique demands of electric vehicle manufacturing.",
  "automotive/ev":
    "Welcome to Pillarhouse, your leading partner in soldering solutions for the dynamic automotive industry. With unmatched expertise and state-of-the-art technology, we specialize in delivering high-quality soldering services designed to meet the specialized demands of vehicle manufacturing, including electric vehicles and beyond.",
  aerospace:
    "With Pillarhouse, you gain the confidence to handle complex assemblies, maintain production efficiency, and achieve the highest performance standards, even in the most demanding applications.",
};

export const RELATED_PRODUCTS = {
  aerospace: [
    "Synchrodex Pro 1600 Pre-Heater Module",
    "Synchrodex Pro 1600 Dual Bath Solder Module",
    "Jade Pro",
  ],
  "automotive/ev": [
    "Orissa Fusion 4",
    "Orissa Synchrodex Solder Module",
    "Orissa Synchrodex Pro 1060mm Solder Module",
  ],
  medical: [
    "Orissa Fusion Pro 508",
    "Orissa Fusion 4",
    "Orissa Synchrodex Pro 1060mm Solder Module",
  ],
};
